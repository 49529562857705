import {Component, OnInit, ChangeDetectionStrategy, HostBinding, Input} from '@angular/core';
import {Location} from '@angular/common';

declare var $;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqComponent implements OnInit {

  @HostBinding('class.embedded') @Input() embedded = false;

  constructor(private location: Location) {
  }

  ngOnInit() {
  }

}
