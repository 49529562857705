import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Game, HasGameId} from '../models/game';
import {Casino} from '../models/casino';
import {CasinoResult} from '../models/casino-result';
import {BallDrawResult} from '../models/balldraw-result';
import {JackpotResult} from '../models/jackpot-result';
import {GameResult} from '../models/game-result';
import {BallDraw} from '../models/balldraw';
import {HotColdNumbers} from '../models/ColdNumbers';
import { Jackpot } from '../models/jackpot';
import { Ticket } from '../models/ticket';


@Injectable({
  providedIn: 'root'
})
export class CasinoService {

  constructor(private http: HttpClient) {
  }

  allCasinos() {
    return this.http.get<CasinoResult>(`/kenocloud/casino/all`);
  }

  allGames() {
    return this.http.get<GameResult>(`/kenocloud/game/all`);
  }

  casino(name: string) {
    return this.http.get<Casino>(`/kenocloud/casino/${name}?format=json`);
  }

  casinoGames(casino: Casino) {
    return this.http.get<GameResult>(`/kenocloud/casino/${casino.casinoid}/game/all`);
  }

  gameById(casino: Casino, game: HasGameId) {
    return this.http.get<GameResult>(`/kenocloud/casino/${casino.casinoid}/game/${game.gameid}`);
  }

  jackpots(casino: Casino) {
    return this.http.get<JackpotResult>(`/kenocloud/casino/${casino.casinoid}/jackpot/all`)
      .pipe(map(jackpots => {
        jackpots.results.sort((a, b) => b.jackpotsid - a.jackpotsid);
        const finalJackpots: Jackpot[] = [];
        for (const jackpot of jackpots.results) {
          if (finalJackpots.findIndex(j => j.id === jackpot.id) < 0) {
            finalJackpots.push(jackpot);
          }
        }
        finalJackpots.sort((a, b) => a.id - b.id);
        jackpots.results = finalJackpots;
        return jackpots;
      }))
      ;
  }

  hotNumbers(casino: Casino, game: HasGameId, numberBallsToGet: number) {
    return this.http.get<HotColdNumbers>(`/kenocloud/casino/${casino.casinoid}/game/${game.gameid}/hotNumbers?size=${numberBallsToGet}&format=json`);
  }

  coldNumbers(casino: Casino, game: HasGameId, numberBallsToGet: number) {
    return this.http.get<HotColdNumbers>(`/kenocloud/casino/${casino.casinoid}/game/${game.gameid}/coldNumbers?size=${numberBallsToGet}&format=json`);
  }

  latestBallDrawFor(casino: Casino, game: HasGameId) {
    return this.http.get<BallDraw>(`/kenocloud/casino/${casino.casinoid}/game/${game.gameid}/balldraw/latest`);
  }

  allBallDrawsFor(casino: Casino, game: HasGameId) {
    return this.http.get<BallDrawResult>(`/kenocloud/casino/${casino.casinoid}/game/${game.gameid}/balldraw/all`);
  }

  getBallDraw(casino: Casino, game: HasGameId, raceId: number) {
    return this.http.get<BallDraw>(`/kenocloud/casino/${casino.casinoid}/game/${game.gameid}/balldraw/${raceId}`);
  }

  nextBallDraw(casino: Casino, game: HasGameId, raceId: number) {
    return this.http.get<BallDraw>(`/kenocloud/casino/${casino.casinoid}/game/${game.gameid}/balldraw/${raceId}/next`);
  }

  previousBallDraw(casino: Casino, game: HasGameId, raceId: number) {
    return this.http.get<BallDraw>(`/kenocloud/casino/${casino.casinoid}/game/${game.gameid}/balldraw/${raceId}/previous`);
  }

  getTickets(casino: Casino, game: HasGameId) {
    return this.http.get<BallDraw>(`/kenocloud/casino/${casino.casinoid}/game/${game.gameid}/ticket/all`);
  }

  getTicketWithIdAndPIN(casino: Casino, game: HasGameId, ticketId: string, ticketPIN: string) {
    return this.http.post<Ticket>(`/kenocloud/casino/${casino.casinoid}/game/${game.gameid}/ticket`, {
      ticketid: ticketId,
      pin: ticketPIN,
    });
  }

  getBallDrawWithNumberAndDate(casino: Casino, game: HasGameId, raceNumber: string, date: Date) {
    return this.http.post<BallDraw>(`/kenocloud/casino/${casino.casinoid}/game/${game.gameid}/balldraw`, {
      racenumber: raceNumber,
      date: date,
    });
  }
}
