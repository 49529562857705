import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import { GameMenuOption, GameMenuComponent } from './components/game-menu/game-menu.component';
import { Config } from './config';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Jackpot } from './models/jackpot';
import { CasinoService } from './services/casino.service';
import { Casino } from './models/casino';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  versionNumber: string;

  gamingArtsLogo: string;
  kenoCloudLogo: string;
  menuImage: string;
  muteOffImage: string;
  muteOnImage: string;

  intervalsToClear: number[] = [];

  menuOptions: GameMenuOption[];

  homePageCasino: Casino;
  jackpots: Jackpot[];

  @ViewChild('gameMenu') _gameMenu: GameMenuComponent;

  constructor(private spinner: NgxSpinnerService,
    public route: ActivatedRoute, private router: Router, private config: Config,
    private casinoService: CasinoService,
  ) {
    this.versionNumber = config.versionNumber;

    this.gamingArtsLogo = config.asset_roots.images_root + '/gamingArtsLogo.png';
    this.kenoCloudLogo = config.asset_roots.images_root + '/Keno_Cloud_Logo.png';

    this.menuImage = config.asset_roots.images_root + '/icons/icon_menu.png';
    this.muteOnImage = config.asset_roots.images_root + '/icons/icon_sound_on.png';
    this.muteOffImage = config.asset_roots.images_root + '/icons/icon_sound_off.png';

    this.menuOptions = [
      {
        title: 'Home',
        image: config.asset_roots.images_root + '/icons/icon_menu_home.png',
        onClick: () => this.router.navigate(['home']),
      },
      {
        title: 'Help',
        image: config.asset_roots.images_root + '/icons/icon_menu_helpfaq.png',
        onClick: () => this.router.navigate(['help']),
      },
      {
        title: 'FAQs',
        image: config.asset_roots.images_root + '/icons/icon_menu_helpfaq.png',
        onClick: () => this.router.navigate(['faq']),
      },
      {
        title: 'Disclaimer',
        image: config.asset_roots.images_root + '/icons/icon_menu_disclaimer.png',
        onClick: () => this.router.navigate(['disclaimer']),
      },
      {
        title: 'Privacy Policy',
        image: config.asset_roots.images_root + '/icons/icon_menu_disclaimer.png',
        onClick: () => this.router.navigate(['privacy']),
      },
    ];

    // whenever the route changes, update the body's class depending on whether the route is no-scroll
    router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe(e => {
      document.body.classList.toggle('no-scroll', (this.route.firstChild && this.route.firstChild.snapshot.data['noScroll']) === true);
    });
  }

  ngOnInit() {
    this.jackpots = [
      {name: 'Quick Hit 3', amount: 1000000 * 100, jackpotsid: 0, id: 0, casinoid: 0, islinked: false},
      {name: 'Quick Hit 3 Mini', amount: 100000 * 100, jackpotsid: 0, id: 0, casinoid: 0, islinked: false},
    ];
  }

  ngOnDestroy() {
    this.intervalsToClear.forEach(i => clearInterval(i));
    this.intervalsToClear = [];
  }
}
