import { Subject } from 'rxjs';

const SETTING_KEYS: string[] = [];
function setting(target: any, key: string): any {
  SETTING_KEYS.push(key);
}

export class Settings {

  static readonly KEYS = SETTING_KEYS;

  @setting enableAnimations = true;
  @setting enableAudio = true;
  @setting hotColdHistoryCount = 50;
  @setting previouslyLoadedCasino = null;
  @setting previouslyLoadedGame = 1;

  saved = new Subject<Settings>();

  getKey(key: string) {
    return 'settings-' + key;
  }

  constructor() {
    for (const key of SETTING_KEYS) {
      if (localStorage.getItem(this.getKey(key)) !== null) {
        this[key] = JSON.parse(localStorage.getItem(this.getKey(key)));
      }
    }
  }

  save() {
    for (const key of SETTING_KEYS) {
      localStorage.setItem(this.getKey(key), JSON.stringify(this[key]));
    }
    this.saved.next(this);
  }
}
