import {Component, OnInit, ViewChild, ElementRef, Output, EventEmitter} from '@angular/core';
import {Config} from '../../config';
import { Casino, CasinoGame } from 'src/app/models/casino';
import { CasinoService } from 'src/app/services/casino.service';
import { Settings } from 'src/app/settings';
import { CasinoFilesService } from 'src/app/services/casino-files.service';
import { Game } from 'src/app/models/game';
import * as moment from 'moment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  headlineImage: string;

  casinos: Casino[];
  games: Game[];
  casinoGames: CasinoGame[];
  deactivatedCasinoId: number;

  @ViewChild('mapCasinoListContainer') _mapCasinoListContainer: ElementRef<HTMLDivElement>;

  constructor(
    private config: Config, public settings: Settings,
    public filesService: CasinoFilesService, private casinoService: CasinoService
  ) {
    this.headlineImage = config.asset_roots.images_root + '/Home_Headline.png';
  }

  ngOnInit() {
    Promise.all([this.casinoService.allCasinos().toPromise(), this.casinoService.allGames().toPromise()])
      .then(([casinos, games]) => {
        this.casinos = this.filterCasinos(casinos.results);
        this.games = games.results;
        this.casinoGames = this.games
          .map(g => ({casino: this.casinos.find(c => c.casinoid === g.casinoid), game: g}))
          .filter(cg => cg.casino && cg.game)
          ;
        this.casinoGames.sort((a, b) => Math.random() < 0.5 ? 1 : -1); // shuffle
      })
      ;
  }

  scrollPanelToState(state: string) {
    // disable previous game panel
    this.settings.previouslyLoadedCasino = null;
    // then scroll panel after it's visible
    setTimeout(() => {
      const stateHeader = this._mapCasinoListContainer.nativeElement.querySelector(`[data-state="${state}"]`);
      if (stateHeader) {
        stateHeader.scrollIntoView({behavior: 'smooth'});
      }
    }, 50);
  }

  filterCasinos(results: Casino[]): Casino[] {
    results.forEach((i, idx) => {
      this.config.sunsetProperties.forEach((j) => {
        const today = moment();
        const activateDate = moment(j.activateDate);
        if (i.casinoid === j.casinoid && activateDate.isSameOrAfter(today, 'day')) {
          results.splice(idx, 1);
          if(this.settings.previouslyLoadedCasino){
            if (this.settings.previouslyLoadedCasino.casinoid === j.casinoid) {
              this.deactivatedCasinoId  = j.casinoid;
            }
          }
        }
      })
    });
    return results;
  }
  
}
