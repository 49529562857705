export interface RunPerFrame {
    options: RunPerFrameOptions;
    run: () => void;
    stop: () => void;
}

export class RunPerFrameOptions {
    autorun ?= true;
    runDelay ?= 0;
    once ?= false;
}

export default function runPerFrame(func: (dt: number, runner: RunPerFrame) => void, options?: RunPerFrameOptions) {
    options = {...new RunPerFrameOptions(), ...options};
    let runner: RunPerFrame = null;

    let running = true;
    let prevT = performance.now();
    const funcLoop = () => {
        if (running) {
            const newT = performance.now();
            const dt = Math.min(newT - prevT, 1000);
            prevT = newT;
            func(dt, runner);
            requestAnimationFrame(funcLoop);
        }
    };

    let startT = performance.now();
    const waitForDelay = () => {
        if (running) {
            if ((performance.now() - startT) >= options.runDelay) {
                prevT = performance.now();
                funcLoop();
                if (options.once) {
                    stop();
                }
            } else {
                requestAnimationFrame(waitForDelay);
            }
        }
    };

    const run = () => {
        running = true;
        startT = performance.now();
        waitForDelay();
    };
    const stop = () => {
        running = false;
    };

    runner = {
        options: options,
        run: run,
        stop: stop,
    };
    if (options.autorun) { // must autorun at teh end after the runner object has been defined
        run();
    }
    return runner;
}
