import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { Casino, CasinoGame } from 'src/app/models/casino';
import { CasinoFilesService } from 'src/app/services/casino-files.service';
import { Config } from 'src/app/config';
import { Router } from '@angular/router';

interface CasinoGroup {
  state: string;
  state_name: string;
  casinoGames: CasinoGame[];
}

@Component({
  selector: 'casino-list',
  templateUrl: './casino-list.component.html',
  styleUrls: ['./casino-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CasinoListComponent implements OnInit {

  @Input() casinoGames: CasinoGame[];

  goToImage: string;

  constructor(private config: Config, private router: Router, public filesService: CasinoFilesService) {
    this.goToImage = config.asset_roots.images_root + '/icons/icon_goto.png';
  }

  key(index, item) { return item[0]; }
  casinoid(index, item) { return item.casinoid; }

  ngOnInit() {
    const x = [0, 's'];
  }

  getCasinoGroups() {
    const casinoGames = this.casinoGames || [];
    const groups = casinoGames.reduce((allGroups, cg) => {
      const group = allGroups.find(g => g.state === cg.casino.state) || (allGroups.push({state: cg.casino.state, state_name: cg.casino.state_name, casinoGames: []}) && allGroups[allGroups.length - 1]);
      group.casinoGames.push(cg);
      return allGroups;
    }, <CasinoGroup[]>[]);
    groups.sort((a, b) => a.state.localeCompare(b.state));
    for (const g of groups) {
      g.casinoGames.sort((a, b) => a.casino.casinoname.localeCompare(b.casino.casinoname) || (a.game.gameid - b.game.gameid));
    }
    return groups;
  }

  goToCasinoGame(casinoGame: CasinoGame) {
    this.router.navigate(['game', casinoGame.casino.casinoname, casinoGame.game.gameid]);
  }

}
