import { Component, OnInit, Input } from '@angular/core';
import { Config } from 'src/app/config';

@Component({
  selector: 'ball-call-platter',
  templateUrl: './ball-call-platter.component.html',
  styleUrls: ['./ball-call-platter.component.css']
})
export class BallCallPlatterComponent implements OnInit {

  @Input() ballDrawIndex: number;

  numberImageRoot: string;
  platterImage: string;

  constructor(config: Config) {
    this.numberImageRoot = config.asset_roots.images_root + '/icons/icon_ballcall_';
    this.platterImage = config.asset_roots.images_root + '/icons/icon_platter.png';
  }

  ngOnInit() {
  }

  getNumberImage() {
    return this.numberImageRoot + (this.ballDrawIndex < 9 ? '0' : '') + (this.ballDrawIndex + 1) + '.png';
  }
}
