import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {GameComponent} from './components/game/game.component';
import {HomeComponent} from './components/home/home.component';
import {AppRoutingModule} from './app-routing.module';
import {UiSwitchModule} from 'ngx-ui-switch';
import {HttpClientModule} from '@angular/common/http';

import {Config} from './config';
import {Settings} from './settings';
import {Ng2OdometerModule} from 'ng2-odometer';
import {FormsModule} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {FaqComponent} from './components/faq/faq.component';
import {HelpComponent} from './components/help/help.component';
import {Ng4LoadingSpinnerModule} from 'ng4-loading-spinner';
import {NgbAlertModule, NgbModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerModule} from 'ngx-spinner';
import {BallColumnComponent} from './components/ball-column/ball-column.component';
import {BallComponent} from './components/ball/ball.component';
import {QuickHit3Component} from './components/quick-hit-3/quick-hit-3.component';
import {CasinoService} from './services/casino.service';
import {TimezoneService} from './services/timezone.service';
import {TilesComponent} from './components/tiles/tiles.component';
import {PreloadImagesComponent} from './components/preload-images/preload-images.component';
import { CanvasAnimationComponent } from './components/canvas-animation/canvas-animation.component';
import { ModalComponent } from './components/modal/modal.component';
import { GridSelectionComponent } from './components/grid-selection/grid-selection.component';
import { MultiplierWheelComponent } from './components/multiplier-wheel/multiplier-wheel.component';
import { GoldenBallComponent } from './components/golden-ball/golden-ball.component';
import { BallCallPlatterComponent } from './components/ball-call-platter/ball-call-platter.component';
import { GameMenuComponent } from './components/game-menu/game-menu.component';
import { JackpotCarouselComponent } from './components/jackpot-carousel/jackpot-carousel.component';
import { DollarDisplayComponent } from './components/dollar-display/dollar-display.component';
import { RecentBallDrawsComponent } from './components/recent-ball-draws/recent-ball-draws.component';
import { InfoPanelComponent } from './components/info-panel/info-panel.component';
import { CasinoFilesService } from './services/casino-files.service';
import { CurrentGameService } from './services/current-game.service';
import { CasinoListComponent } from './components/casino-list/casino-list.component';
import { PreviousGamePanelComponent } from './components/previous-game-panel/previous-game-panel.component';
import { CasinoMapComponent } from './components/casino-map/casino-map.component';
import { CasinoCarouselComponent } from './components/casino-carousel/casino-carousel.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { CloudsComponent } from './components/clouds/clouds.component';
import { InnerVCSSComponent } from './components/inner-v-css/inner-v-css.component';
import { AudioService } from './services/audio.service';
import { PrintBallDrawsComponent } from './components/print-ball-draws/print-ball-draws.component';
import { TempDataService } from './services/temp-data.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';


@NgModule({
  declarations: [
    AppComponent,
    BallComponent,
    BallColumnComponent,
    GameComponent,
    HomeComponent,
    FaqComponent,
    HelpComponent,
    QuickHit3Component,
    TilesComponent,
    PreloadImagesComponent,
    CanvasAnimationComponent,
    ModalComponent,
    GridSelectionComponent,
    MultiplierWheelComponent,
    GoldenBallComponent,
    BallCallPlatterComponent,
    GameMenuComponent,
    JackpotCarouselComponent,
    DollarDisplayComponent,
    RecentBallDrawsComponent,
    InfoPanelComponent,
    CasinoListComponent,
    PreviousGamePanelComponent,
    CasinoMapComponent,
    CasinoCarouselComponent,
    DisclaimerComponent,
    PrivacyComponent,
    CloudsComponent,
    InnerVCSSComponent,
    PrintBallDrawsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    UiSwitchModule.forRoot({
      checkedLabel: 'ON',
      uncheckedLabel: 'OFF',
    }),
    Ng2OdometerModule.forRoot(),
    HttpClientModule,
    Ng4LoadingSpinnerModule.forRoot(),
    NgbModule,
    NgbPaginationModule, NgbAlertModule, NgxSpinnerModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    TempDataService,
    CasinoService,
    CasinoFilesService,
    AudioService,
    TimezoneService,
    CurrentGameService,
    DatePipe,
    Config,
    Settings,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
