import { Injectable } from '@angular/core';
import { Casino } from '../models/casino';
import { Game } from '../models/game';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentGameService {

  casino: Casino;
  game: Game;

  subscribers: ((service: CurrentGameService) => void)[] = [];

  constructor() {
  }

  subscribe(callback: (service: CurrentGameService) => void) {
    this.subscribers.push(callback);
    if (this.casino && this.game) {
      callback(this);
    }
  }
  unsubscribe(callback: (service: CurrentGameService) => void) {
    this.subscribers = this.subscribers.filter(s => s !== callback);
  }

  set(casino: Casino, game: Game) {
    this.casino = casino;
    this.game = game;
    this.subscribers.forEach(sub => sub(this));
  }

  clear() {
    this.casino = null;
    this.game = null;
  }
}
