import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, ViewChildren, QueryList, ChangeDetectionStrategy } from '@angular/core';
import { Jackpot } from 'src/app/models/jackpot';
import Swiper, { SwiperOptions } from 'swiper';
import { Config } from 'src/app/config';
import { Casino, CasinoGame } from 'src/app/models/casino';
import { Game } from 'src/app/models/game';
import { CasinoFilesService } from 'src/app/services/casino-files.service';
import { Router } from '@angular/router';

@Component({
  selector: 'casino-carousel',
  templateUrl: './casino-carousel.component.html',
  styleUrls: ['./casino-carousel.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CasinoCarouselComponent implements OnInit, AfterViewInit {

  @Input() casinoGames: CasinoGame[];

  @ViewChild('swiper') _swiper: ElementRef<HTMLDivElement>;
  @ViewChild('swiperPagination') _swiperPagination: ElementRef<HTMLDivElement>;
  @ViewChildren('slide') _slides: QueryList<ElementRef<HTMLDivElement>>;

  swiper: Swiper;

  constructor(
	config: Config,
	private router: Router,
	public filesService: CasinoFilesService
  ) {}

  casinonamegameid(index, item) { return item.casino.casinoname + item.game.gameid; }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this._slides.changes.subscribe(() => {
      this.swiper = new Swiper(this._swiper.nativeElement, {
        direction: 'horizontal',
        spaceBetween: 12,
        slidesPerView: 5,
        grabCursor: true,
        speed: 1000,
        autoplay: {
          delay: 1100,
          disableOnInteraction: false,
        },
        loop: true,
        pagination: {
          el: this._swiperPagination.nativeElement,
          clickable: true,
        },
      });
    });
  }

  goToCasinoGame(casinoGame: CasinoGame) {
    this.router.navigate(['game', casinoGame.casino.casinoname, casinoGame.game.gameid]);
  }

}
