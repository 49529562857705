import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Casino, CasinoGame } from 'src/app/models/casino';
import { Settings } from 'src/app/settings';

@Component({
  selector: 'casino-map',
  templateUrl: './casino-map.component.html',
  styleUrls: ['./casino-map.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CasinoMapComponent implements OnInit {

  _casinos: CasinoGame[];
  availableStates: string[];
  @Input() set casinos(casinos: CasinoGame[]) {
    this._casinos = casinos || [];
    this.availableStates = [];
    for (const state of this._casinos.map(c => c.casino.state)) {
      if (!this.availableStates.includes(state)) {
        this.availableStates.push(state);
      }
    }
  }

  @Output() stateSelected: EventEmitter<string> = new EventEmitter<string>();

  constructor(public settings: Settings) { }

  ngOnInit() {
  }

  selectState(state: string) {
    if (this.availableStates.includes(state)) {
      this.stateSelected.emit(state);
    }
  }

}
