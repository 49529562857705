import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {GameComponent} from './components/game/game.component';
import {FaqComponent} from './components/faq/faq.component';
import {HelpComponent} from './components/help/help.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { PrintBallDrawsComponent } from './components/print-ball-draws/print-ball-draws.component';


const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    data: { noScroll: true },
  },
  {
    path: 'help',
    component: HelpComponent,
  },
  {
    path: 'faq',
    component: FaqComponent,
  },
  {
    path: 'disclaimer',
    component: DisclaimerComponent,
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: 'game/:name/:gameid',
    component: GameComponent,
    data: { skipWrapper: true, noScroll: true },
  },
  {
    path: 'game/:name',
    redirectTo: 'game/:name/1',
  },
  {
    path: '**',
    redirectTo: 'home',
  },
  {
    path: 'print/draws',
    outlet: 'print',
    component: PrintBallDrawsComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
