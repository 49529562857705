import { Component, OnInit, Input, OnDestroy, ViewChildren, QueryList, ElementRef, AfterViewInit, Inject, LOCALE_ID, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import runPerFrame, { RunPerFrame } from 'src/app/runPerFrame';
import { lerp, getDigitAtPosition } from 'src/app/util';
import { CurrencyPipe, getLocaleId } from '@angular/common';

@Component({
  selector: 'dollar-display',
  templateUrl: './dollar-display.component.html',
  styleUrls: ['./dollar-display.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DollarDisplayComponent implements OnInit, OnDestroy, AfterViewInit {
  DIGIT_STRINGS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  @Input() set value(value: number) {
    this.targetValue = value;
    // initialize lerped value and digit display if this is the initial set value
    if (this.lerpingValue === 0) {
      this.lerpingValue = value;
      this.setDigits(this.lerpingValue);
    }
  }
  targetValue = 0;
  lerpingValue = 0;
  @Input() lerpSpeed = 0.12;

  digits = [];
  @ViewChildren('digitCell') _digitCells: QueryList<ElementRef<HTMLDivElement>>;

  digitLerpAndRenderRunner: RunPerFrame;

  constructor(@Inject(LOCALE_ID) private locale: string, private changeDetection: ChangeDetectorRef) { }

  index(index) { return index; }

  setDigits(value: number) {
    const displayText = new CurrencyPipe(this.locale).transform(value);
    this.digits = displayText.split('');
    this.changeDetection.detectChanges();
  }

  ngOnInit() {
    this.digitLerpAndRenderRunner = runPerFrame(dt => {
      if (Math.abs(this.lerpingValue - this.targetValue) < 0.005) {
        return;
      }
      // lerp value exponentially towards target
      this.lerpingValue = lerp(this.lerpingValue, this.targetValue, this.lerpSpeed);
      // set digits based on current lerped value
      this.setDigits(this.lerpingValue);
    }, {autorun: false});
  }

  ngOnDestroy() {
    this.digitLerpAndRenderRunner.stop();
  }

  ngAfterViewInit() {
    this.digitLerpAndRenderRunner.run();
  }

}
