import { Component, OnInit, Input, ChangeDetectionStrategy, ViewChildren, QueryList, ElementRef, AfterViewInit } from '@angular/core';
import { GridState } from 'src/app/enums';
import { Settings } from 'src/app/settings';

@Component({
  selector: 'tiles',
  templateUrl: './tiles.component.html',
  styleUrls: ['./tiles.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TilesComponent implements OnInit, AfterViewInit {

  @Input() displayState: GridState;
  @Input() startAt: number;
  @Input() balls: number[];
  @Input() luckyNumbers: number[];
  @Input() hotNumbers: number[];
  @Input() coldNumbers: number[];

  animDelays: string[] = [];

  @ViewChildren('tile') _tiles: QueryList<ElementRef<HTMLDivElement>>;
  _tilesArray: HTMLDivElement[];

  constructor(public settings: Settings) {
  }

  index(index) { return index; }

  ngOnInit() {
    for (let i = 0; i < 40; i++) {
      this.animDelays.push((Math.random() * 0.8) + 's');
    }
  }

  ngAfterViewInit() {
    this._tilesArray = this._tiles.map(el => el.nativeElement);
    this._tiles.changes.subscribe(() => {
      this._tilesArray = this._tiles.map(el => el.nativeElement);
    });
  }

  getTileByNumber(number: number) {
    return this._tilesArray[number - this.startAt];
  }

  isActive(tileNumber: number) {
    return this.displayState === GridState.Game && (this.balls || []).indexOf(tileNumber) >= 0;
  }
  isLucky(tileNumber: number) {
    return (this.luckyNumbers || []).indexOf(tileNumber) >= 0;
  }
  isHot(tileNumber: number) {
    return this.displayState === GridState.Hot && (this.hotNumbers || []).indexOf(tileNumber) >= 0;
  }
  isCold(tileNumber: number) {
    return this.displayState === GridState.Cold && (this.coldNumbers || []).indexOf(tileNumber) >= 0;
  }
  getAnimDelay(tileNumber: number) {
    switch (this.displayState) {
      case GridState.Game: return '0s';
      case GridState.Hot: return this.settings.enableAnimations ? Math.max(0, (this.hotNumbers || []).indexOf(tileNumber) * 0.09) + 's' : '0s';
      case GridState.Cold: return this.settings.enableAnimations ? Math.max(0, (this.coldNumbers || []).indexOf(tileNumber) * 0.09) + 's' : '0s';
    }
  }
}
