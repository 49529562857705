import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Config } from 'src/app/config';

@Component({
  selector: 'clouds',
  templateUrl: './clouds.component.html',
  styleUrls: ['./clouds.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloudsComponent implements OnInit {

  cloud1: string;
  cloud2: string;
  cloud3: string;
  cloud4: string;
  cloud5: string;

  constructor(private config: Config) {
    this.cloud1 = this.config.asset_roots.images_root + '/clouds/cloud1.png';
    this.cloud2 = this.config.asset_roots.images_root + '/clouds/cloud2.png';
    this.cloud3 = this.config.asset_roots.images_root + '/clouds/cloud3.png';
    this.cloud4 = this.config.asset_roots.images_root + '/clouds/cloud4.png';
    this.cloud5 = this.config.asset_roots.images_root + '/clouds/cloud5.png';
  }

  ngOnInit() {
  }

}
