import { Component, OnInit, Input, HostBinding } from '@angular/core';

export class GameMenuOption {
  title: string;
  image: string;
  onClick: () => void;
}

@Component({
  selector: 'game-menu',
  templateUrl: './game-menu.component.html',
  styleUrls: ['./game-menu.component.css']
})
export class GameMenuComponent implements OnInit {

  @HostBinding('class.show-menu') showMenu = false;

  @Input() headerImage: string;
  @Input() options: GameMenuOption[];
  @Input() showGameSearch: boolean;
  @Input() showTicketSearch: boolean;
  @Input() showMyNumbers: boolean;

  constructor() {

  }

  ngOnInit() {
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  optionClicked(option: GameMenuOption) {
    this.showMenu = false;
    option.onClick();
  }

  shouldShowOption(option: GameMenuOption):boolean {
    if (option.title == "Game Search" && !this.showGameSearch) return false;
    if (option.title == "Lucky Numbers" && !this.showMyNumbers) return false;
    if (option.title == "Ticket Search" && !this.showTicketSearch) return false;
    return true;
  }
}
