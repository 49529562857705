import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { PreloadImagesComponent } from '../preload-images/preload-images.component';
import runPerFrame from 'src/app/runPerFrame';

@Component({
  selector: 'canvas-animation',
  templateUrl: './canvas-animation.component.html',
  styleUrls: ['./canvas-animation.component.css']
})
export class CanvasAnimationComponent implements OnInit, AfterViewInit {

  @Input() preloadedImages: PreloadImagesComponent;
  @Input() width: number;
  @Input() height: number;
  @Input() fps = 24;
  @Input() offsetX = 0;
  @Input() offsetY = 0;
  @Input() playing = false;
  @Input() loop = false;
  @Input() stopAtFirstFrame = false;

  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  imgs: HTMLImageElement[];
  animationTime = -1; // a value of -1 makes the first runner update to trigger rendering to the canvas

  @ViewChild('canvas') _canvas: ElementRef<HTMLCanvasElement>;

  constructor() { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.canvas = this._canvas.nativeElement;
      this.ctx = this.canvas.getContext('2d');

      this.imgs = this.preloadedImages._imgs.toArray().map(ref => ref.nativeElement);

      const runner = runPerFrame(dt => {
        if (!this.playing) {
          return;
        }
        const frameDuration = 1000 / this.fps;
        const animationDuration = frameDuration * this.imgs.length;
        const looped = (this.animationTime + dt) >= animationDuration;
        const prevFrame = Math.floor(this.animationTime / frameDuration);
        this.animationTime = (this.animationTime + dt) % animationDuration;
        const currFrame = Math.floor(this.animationTime / frameDuration);
        if (prevFrame !== currFrame) {
          const frameToDraw = this.stopAtFirstFrame ? 0 : currFrame;
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
          this.ctx.drawImage(this.imgs[frameToDraw], this.canvas.width * this.offsetX, this.canvas.height * this.offsetY);
        }
        if (this.stopAtFirstFrame) {
          this.playing = false;
          this.stopAtFirstFrame = false;
          if (!this.imgs[0].complete) { // keep trying until the image is loaded
            this.drawFirstFrame();
          }
        }
        if (looped) {
          if (!this.loop) {
            this.playing = false;
          }
        }
      });

      this.drawFirstFrame();
    }, 100);
  }

  ngOnInit() {
  }

  replayAnimation() {
    this.playing = true;
    this.animationTime = -1;
  }

  clear() {
    this.playing = false;
    if (this.ctx) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }
  }

  drawFirstFrame() {
    this.replayAnimation();
    this.stopAtFirstFrame = true;
  }

}
