import { IANAZone, DateTime } from 'luxon';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  readonly ianaTimezones = [
      'America/Los_Angeles',
	  'America/Chicago',
      'America/New_York',
      'America/Denver',
      'America/Detroit',
      'America/Phoenix'
    ].map(tz => new IANAZone(tz))
    ;

  constructor() {
  }

  parseISODateWithOriginalTimezone(isoString: string) {
    if (!isoString) {
      return null;
    }
    const fixedTimezoneDate = DateTime.fromISO(isoString, {setZone: true});
    const millis = fixedTimezoneDate.toMillis();
    const ianaTimezone = this.ianaTimezones.find(tz => tz.offset(millis) === fixedTimezoneDate.offset);
    if (ianaTimezone) {
      return fixedTimezoneDate.setZone(ianaTimezone);
    } else {
      return fixedTimezoneDate;
    }
  }

  formatISODateWithOriginalTimezone(isoString: string, formatString: string) {
    if (!isoString) {
      return null;
    }
    return this.parseISODateWithOriginalTimezone(isoString).toFormat(formatString);
  }
}
