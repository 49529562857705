import { Injectable } from '@angular/core';
import { Casino, HasCasinoName } from '../models/casino';

class MultiplierJSON {
  wheelAngles: { [key: string]: number; };
}

@Injectable({
  providedIn: 'root'
})
export class CasinoFilesService {

  validationResults: { [key: string]: boolean; } = {};

  constructor() {
  }

  private printValidationError(casino: HasCasinoName, url: string, errorCode: number, errorMessage: string) {
    const styleHeader = 'font-weight: bold; background-color: #ff8cca; padding: 3px;';
    console.log('%c!!! Invalid Casino File !!!', 'font-size: 18px; font-weight: bold; background-color: #ff8cca;');
    if (casino) {
      console.log('%cCasino:  ', styleHeader, casino.casinoname);
    }
    console.log('%cRelated URL:    ', styleHeader, url);
    console.log('%cError:          ', styleHeader, `${errorCode} - ${errorMessage}`);
  }

  validateUrl(casino: HasCasinoName, url: string) {
    if (url && this.validationResults[url] === undefined) {
      this.validationResults[url] = false;
      fetch(url).then(res => {
        if (res.status === 200) {
          this.validationResults[url] = true;
        } else {
          this.printValidationError(casino, url, res.status, res.statusText);
        }
      });
    }
    return url;
  }

  validateMultiplierJson(casino: Casino, url: string) {
	return fetch(url).then(res => {
        if (res.status === 200) {
          this.validationResults[url] = true;
          return res.json();
        }
        this.printValidationError(casino, url, res.status, res.statusText);
        return {};
	}).then(json => {
		const multiplierJson = <MultiplierJSON>json;
		if (!multiplierJson || !multiplierJson.wheelAngles) {
		  this.printValidationError(casino, null, 904, `Missing "wheelAngles" property`);
		} else {
		  let i = 0;
		  for (const key in multiplierJson.wheelAngles) {
			if (multiplierJson.wheelAngles.hasOwnProperty(key)) {
			  i++;
			  if (!key.endsWith('x')) {
				this.printValidationError(casino, null, 906, `Invalid key on "wheelAngles[${key}]" property: key must be a string ending in lowercase "x"`);
			  }
			  if (typeof multiplierJson.wheelAngles[key] !== 'number') {
				this.printValidationError(casino, null, 906, `Invalid value on "wheelAngles[${key}]" property: value must be a number representing degrees of rotation`);
			  }
			}
		  }
		  if (i === 0) {
			this.printValidationError(casino, null, 906, `Empty "wheelAngles" property: must have at least 1 entry`);
		  }
		}
		return multiplierJson;
	}).catch(e => {
        this.validationResults[url] = false;
        this.printValidationError(casino, url, 903, e.message);
        return <MultiplierJSON>null;
	})
  }

  getLogo(casino: Casino) {
    return this.validateUrl(casino, casino ? casino.logo : '');
  }
  getDetails(casino: Casino) {
    return this.validateUrl(casino, casino ? casino.details_bg : '');
  }
  getPaytables(casino: Casino) {
    return this.validateUrl(casino, casino ? casino.paytables_image : '');
  }
  getMultiplierWheel(casino: Casino) {
    return this.validateUrl(casino, casino ? `/casino_files/multiplier_wheels/${casino.multiplier_wheel}.png` : '');
  }
  getMultiplierIcon(multiplier: string) {
    return this.validateUrl(null, multiplier ? `/casino_files/multiplier_icons/${multiplier.toLowerCase()}.png` : '');
  }
  getMultiplierJSON(casino: Casino) {
    return this.validateMultiplierJson(casino, casino ? `/casino_files/multiplier_wheels/${casino.multiplier_wheel}.json` : '')
  }
}
