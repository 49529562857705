import { Injectable } from '@angular/core';
import { Howl, Howler } from 'howler';
import { Config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  audio: { [id: string]: Howl } = {};

  set volume(volume: number) {
    Howler.volume(volume);
  }
  get volume() {
    return Howler.volume();
  }
  set mute(muted: boolean) {
    Howler.mute(muted);
  }

  constructor(private config: Config) {
    const root = config.asset_roots.audio_root;
    // ball sounds
    for (let i = 1; i <= 80; i++) {
      this.audio[`ball${i}`] = new Howl({
        src: [`${root}/${i}.webm`, `${root}/${i}.mp3`]
      });
    }
    // misc sounds
    const sounds = [
      { id: 'goodluck', filename: 'goodluck' },
      { id: 'luckynumbers', filename: 'lucky' },
      { id: 'lastball', filename: 'lastball' },
      { id: 'goldenball', filename: 'Golden Keno Ball' },
      { id: 'multiplier-click', filename: 'click2' },
      { id: 'multiplier-number', filename: 'ZING' },
    ];
    for (const sound of sounds) {
      this.audio[sound.id] = new Howl({
        src: [`${root}/${sound.filename}.webm`, `${root}/${sound.filename}.mp3`]
      });
    }
  }

  play(sound: string) {
    if (Howler.ctx && Howler.ctx.state === 'running') {
      this.audio[sound].play();
    } else {
      this.audio[sound].play();
    }
  }

  playBallNumber(number: number) {
    this.play(`ball${number}`);
  }

  stop(sound: string) {
    this.audio[sound].stop();
  }

  stopAll() {
    for (const id in this.audio) {
      if (this.audio.hasOwnProperty(id)) {
        this.stop(id);
      }
    }
  }
}
