import {Component, Input, HostBinding} from '@angular/core';
import {AssetRootConfig} from '../../asset-root-config';


@Component({
  selector: 'ball',
  templateUrl: './ball.component.html',
  styleUrls: ['./ball.component.css']
})
export class BallComponent {

  @HostBinding('class.absolute') @Input() absolute = false;

  @Input() number: number;
  @Input() golden = false;
  @Input() quickHit3 = false;
  @Input() hide = false;

  constructor(private config: AssetRootConfig) {
  }

  getBackgroundURL() {
    if (!(this.number > 0 && this.number <= 80)) {
      return `${this.config.ball_root}/ball_normal.png`;
    } else if (this.golden) {
      return `${this.config.ball_root}/ball_golden.png`;
    } else if (this.quickHit3) {
      return `${this.config.ball_root}/ball_quickHit3.png`;
    } else {
      return `${this.config.ball_root}/ball_normal.png`;
    }
  }
}
