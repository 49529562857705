import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { Config } from 'src/app/config';
import { BallDraw } from 'src/app/models/balldraw';
import { TimezoneService } from 'src/app/services/timezone.service';
import { CasinoFilesService } from 'src/app/services/casino-files.service';
import { Router } from '@angular/router';
import { TempDataService } from 'src/app/services/temp-data.service';
import { PrintBallDrawsData } from '../print-ball-draws/print-ball-draws.component';
import { CurrentGameService } from 'src/app/services/current-game.service';

@Component({
  selector: 'recent-ball-draws',
  templateUrl: './recent-ball-draws.component.html',
  styleUrls: ['./recent-ball-draws.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecentBallDrawsComponent implements OnInit {
  @Input() ballDraws: BallDraw[];
  @Input() luckyNumbers: number[];
  @Input() pageSize = 10;

  @Input() ballsSorted = false;
  @Output() ballsSortedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() pagesToShow = 1;
  @Output() pagesToShowChange: EventEmitter<number> = new EventEmitter<number>();

  @Output() goToBallDraw = new EventEmitter<BallDraw>();

  @Input() printStartNumber = 0;
  @Input() printEndNumber = 0;
  @Input() usePrintEvent = false;
  @Output() print = new EventEmitter<void>();

  goToImage: string;

  constructor(config: Config, private router: Router,
    private tempData: TempDataService, public filesService: CasinoFilesService,
    public currentGameService: CurrentGameService, public timezoneService: TimezoneService
  ) {
    this.goToImage = config.asset_roots.images_root + '/icons/icon_goto.png';
  }

  raceid(index, item: BallDraw) { return item.raceid; }
  item(index, item: any) { return item; }

  ngOnInit() {
  }

  hasMoreBallDraws() {
    return this.ballDraws && (this.pageSize * this.pagesToShow) < this.ballDraws.length;
  }

  getBallDraws() {
    return this.ballDraws ? this.ballDraws.slice(0, this.pageSize * this.pagesToShow) : [];
  }

  getSorted(numbers: number[]) {
    const sorted = [].concat(numbers);
    sorted.sort((a, b) => a - b);
    return sorted;
  }

  goToDraw(ballDraw: BallDraw) {
    this.goToBallDraw.emit(ballDraw);
  }

  isLucky(number: number) {
    return (this.luckyNumbers || []).indexOf(number) >= 0;
  }
  isMillions(ballDraw: BallDraw, number: number) {
    return (ballDraw.bonus_balls || []).indexOf(number) >= 0;
  }
  isGolden(ballDraw: BallDraw, number: number) {
    return ballDraw.megaball === number;
  }

  setBallSort(toggle: boolean) {
    this.ballsSorted = toggle;
    this.ballsSortedChange.emit(this.ballsSorted);
  }

  showAnotherPage() {
    this.pagesToShow++;
    this.pagesToShowChange.emit(this.pagesToShow);
  }

  doPrint() {
    if (this.usePrintEvent) {
      this.print.emit();
    } else {
      this.printDraws();
    }
  }

  printDraws() {
    const ballDrawsSliced = this.ballDraws.slice(0, 999);
    const start = this.printStartNumber || 999;
    const startIndex = this.ballDraws.findIndex(draw => draw.racenumber <= start);
    const startIndexToUse = startIndex === -1 ? 0 : startIndex;
    const end = this.printEndNumber || 1;
    const endIndex = this.ballDraws.findIndex(draw => draw.racenumber === end);
    const endIndexToUse = endIndex === -1 ? 999 : endIndex;
    const ballDrawsFiltered = ballDrawsSliced.filter((_, i) => i >= startIndexToUse && i <= endIndexToUse);
    this.tempData.set<PrintBallDrawsData>('printDrawsData', {
      casino: this.currentGameService.casino,
      game: this.currentGameService.game,
      ballDraws: ballDrawsFiltered,
      ballsSorted: this.ballsSorted,
      luckyNumbers: this.luckyNumbers,
    });
    this.router.navigate(['/', {
      outlets: {
        'print': ['print', 'draws']
      },
    }], { skipLocationChange: true });
  }
}
