import { AssetRootConfig } from './asset-root-config';

export class Config {
  versionNumber = '0.8.1';
  homePageProgressivesCasinoName = 'Gaming Arts';

  asset_roots = new AssetRootConfig();

  // Add an object to the array to turn on/off features for a given property in the application
  // If maxGameSearch and/or maxLuckyNumbers restrictions are not needed, then assign 'undefined' to the objects
  // Example:  "maxGameSearch": undefined,
  restrictedFeatures = [{
    "casinoid": 45,
    "casinoname": "The Orleans Hotel & Casino",
    "state": "NV",
    "state_name": "Nevada",
    "feature": {
      "hotColdNumbers": false,
      "ticketSearch": true,
      "myNumbers": true,
      "multiplier": true,
      "goldenKenoBall": true,
      "maxGameSearch": 10,
      "maxLuckyNumbers": undefined,
      "gameHistoryArrows": false,
      "gameSearch": false,
    }
  },{
    "casinoid": 1,
    "casinoname": "AZ Charlies Decatur",
    "state": "NV",
    "state_name": "Nevada",
    "feature": {
      "hotColdNumbers": true,
      "ticketSearch": true,
      "myNumbers": true,
      "multiplier": true,
      "goldenKenoBall": true,
      "maxGameSearch": undefined,
      "maxLuckyNumbers": undefined,
      "gameHistoryArrows": true,
      "gameSearch": true,
    }
  },{
    "casinoid": 6,
    "casinoname": "Wendover Nugget",
    "state": "NV",
    "state_name": "Nevada",
    "feature": {
      "hotColdNumbers": true,
      "ticketSearch": true,
      "myNumbers": true,
      "multiplier": true,
      "goldenKenoBall": true,
      "maxGameSearch": undefined,
      "maxLuckyNumbers": undefined,
      "gameHistoryArrows": true,
      "gameSearch": true,
    }
  },{
    "casinoid": 48,
    "casinoname": "Players Keno",
    "state": "NE",
    "state_name": "Papillion",
    "feature": {
      "hotColdNumbers": true,
      "ticketSearch": true,
      "myNumbers": true,
      "multiplier": true,
      "goldenKenoBall": true,
      "maxGameSearch": undefined,
      "maxLuckyNumbers": undefined,
      "gameHistoryArrows": true,
      "gameSearch": true,
    }
  },{
    "casinoid": 49,
    "casinoname": "BWs",
    "state": "NE",
    "state_name": "Ashland",
    "feature": {
      "hotColdNumbers": true,
      "ticketSearch": true,
      "myNumbers": true,
      "multiplier": true,
      "goldenKenoBall": true,
      "maxGameSearch": undefined,
      "maxLuckyNumbers": undefined,
      "gameHistoryArrows": true,
      "gameSearch": true,
    }
  },{
    "casinoid": 50,
    "casinoname": "Silver-Slipper",
    "state": "MS",
    "state_name": "Mississippi",
    "feature": {
      "hotColdNumbers": true,
      "ticketSearch": true,
      "myNumbers": true,
      "multiplier": true,
      "goldenKenoBall": true,
      "maxGameSearch": undefined,
      "maxLuckyNumbers": undefined,
      "gameHistoryArrows": true,
      "gameSearch": true,
    }
  },{
    "casinoid": 82,
    "casinoname": "The-California-Hotel-Casino",
    "state": "NV",
    "state_name": "Nevada",
    "feature": {
      "hotColdNumbers": false,
      "ticketSearch": true,
      "myNumbers": true,
      "multiplier": true,
      "goldenKenoBall": true,
      "maxGameSearch": 20,
      "maxLuckyNumbers": undefined,
      "gameHistoryArrows": false,
      "gameSearch": false,
    }
  },{
    "casinoid": 84,
    "casinoname": "Gold-Coast-Hotel-And-Casino",
    "state": "NV",
    "state_name": "Nevada",
    "feature": {
      "hotColdNumbers": false,
      "ticketSearch": true,
      "myNumbers": true,
      "multiplier": true,
      "goldenKenoBall": true,
      "maxGameSearch": undefined,
      "maxLuckyNumbers": undefined,
      "gameHistoryArrows": false,
      "gameSearch": false,
    }
  },{
    "casinoid": 51,
    "casinoname": "Steve-Test-Site",
    "state": "NV",
    "state_name": "Nevada",
    "feature": {
      "hotColdNumbers": false,
      "ticketSearch": true,
      "myNumbers": true,
      "multiplier": false,
      "goldenKenoBall": true,
      "maxGameSearch": 20,
      "maxLuckyNumbers": undefined,
      "gameHistoryArrows": true,
      "gameSearch": true,
    }
  }];

  // Add an object to the array to exclude a property from the application and
  // set the following:
  // 'deactivateDate' - Date when property should not be displayed on the UI
  // 'activateDate'   - Date the property should be displayed on the UI
  sunsetProperties = [{
    "casinoid": 9,
    "casinoname": "Seven Feathers Hotel and Casino",
    "state": "OR",
    "state_name": "Oregon",
    "deactivateDate": "2021-01-01T00:00:00.000Z",
    "activateDate": "2022-01-01T00:00:00.000Z",
  },{
    "casinoid": 35,
    "casinoname": "GA Test Site",
    "state": "NV",
    "state_name": "Nevada",
    "deactivateDate": "2021-01-01T00:00:00.000Z",
    "activateDate": "2022-01-01T00:00:00.000Z",
  }];
}
