import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TempDataService {

  private data: { [key: string]: any } = {};

  constructor() { }

  get<T>(key: string) {
    return <T>this.data[key];
  }
  set<T>(key: string, value: T) {
    this.data[key] = value;
  }
}
