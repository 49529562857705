import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  @HostBinding('class.wrapper') wrapper = true;
  @Input() set useWrapper(use: boolean) {
    this.wrapper = use;
  }

  constructor() { }

  ngOnInit() {
  }

}
