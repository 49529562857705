import { Component, OnInit, Input } from '@angular/core';
import { Config } from 'src/app/config';

@Component({
  selector: 'golden-ball',
  templateUrl: './golden-ball.component.html',
  styleUrls: ['./golden-ball.component.css']
})
export class GoldenBallComponent implements OnInit {

  @Input() balls: number[];
  @Input() goldenBall: number;
  platterImage: string;

  constructor(config: Config) {
    this.platterImage = config.asset_roots.images_root + '/icons/icon_platter.png';
  }

  ngOnInit() {
  }
}
