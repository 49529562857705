import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.css']
})
export class DisclaimerComponent implements OnInit {

  @HostBinding('class.wrapper') wrapper = true;
  @Input() set useWrapper(use: boolean) {
    this.wrapper = use;
  }

  constructor() { }

  ngOnInit() {
  }

}
