import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

(window as any).IS_CORDOVA = window.hasOwnProperty('cordova');

if ((window as any).IS_CORDOVA) {
  document.addEventListener('deviceready', init, false);
} else {
  init();
}

function init() {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
}
