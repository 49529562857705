import {Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy, ChangeDetectionStrategy, HostBinding} from '@angular/core';
import runPerFrame, { RunPerFrame } from 'src/app/runPerFrame';

@Component({
  selector: 'center-quick-hit-3',
  templateUrl: './quick-hit-3.component.html',
  styleUrls: ['./quick-hit-3.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickHit3Component implements OnInit, OnDestroy {

  @Input() quickHit3Logo: string;
  @Input() quickHit3MiniLogo: string;
  @Input() numBalls = 4;
  @Input() bonusBalls: number[];

  @Input() quickHit3Available: boolean;
  @Input() haveYouPlayedImage: string;
  @Input() message: string;
  @Input() scrollSpeedFactor = 0.0122; // has to be hand-tweaked to get a nice speed
  @Input() autoplay = true;

  @Input() forceShowHaveYouPlayed = false;

  currentTimeout: number;

  scrollAmount: number;
  scrollDuration: number;

  @HostBinding('class.center-casino-message') centerCasinoMessage = false;

  @ViewChild('quickHit3') _quickHit3: ElementRef<HTMLDivElement>;
  @ViewChild('haveYouPlayedMessage') _haveYouPlayedMessage: ElementRef<HTMLDivElement>;
  @ViewChild('messageText') _messageText: ElementRef<HTMLDivElement>;

  constructor() {
  }

  index(index) { return index; }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.stop();
  }

  stop() {
    clearTimeout(this.currentTimeout);
  }

  validBonusBalls() {
    return (this.bonusBalls || []).filter(n => n);
  }

  updateCasinoMessageScroll() {
    this.scrollAmount = this._messageText.nativeElement.clientWidth - this._messageText.nativeElement.parentElement.clientWidth;
    this.scrollDuration = Math.max(0, this.scrollSpeedFactor * this.scrollAmount);
    this.centerCasinoMessage = this.scrollAmount <= 0;
  }

  // animation callbacks
  triggerQuickHit3() {
    if (!this.quickHit3Available) {
      this.triggerHaveYouPlayed();
      return;
    }
    this._quickHit3.nativeElement.classList.remove('hide');
    this._haveYouPlayedMessage.nativeElement.classList.add('hide');
    this._messageText.nativeElement.classList.add('hide');
    this.stop();
    this.currentTimeout = window.setTimeout(() => this.triggerHaveYouPlayed(), 6000);
  }
  triggerHaveYouPlayed() {
    this._quickHit3.nativeElement.classList.add('hide');
    this._haveYouPlayedMessage.nativeElement.classList.remove('hide');
    this._messageText.nativeElement.classList.add('hide');
    this.stop();
    this.currentTimeout = window.setTimeout(() => this.triggerCasinoMessage(), 4500);
  }
  triggerCasinoMessage() {
    if (!this.message) {
      this.triggerQuickHit3();
      return;
    }
    this.updateCasinoMessageScroll();
    this._quickHit3.nativeElement.classList.add('hide');
    this._haveYouPlayedMessage.nativeElement.classList.add('hide');
    this._messageText.nativeElement.classList.remove('hide');
    this._messageText.nativeElement.classList.add('scrollToEnd');
    this._messageText.nativeElement.style.setProperty('transition-duration', `0s`, 'important');
    this._messageText.nativeElement.style.transform = null;
    this.stop();
    this.currentTimeout = window.setTimeout(() => this.triggerCasinoMessageScroll(), 2800);
  }
  triggerCasinoMessageScroll() {
    this.updateCasinoMessageScroll();
    if (this.scrollAmount > 0) {
      this._messageText.nativeElement.style.setProperty('transition-duration', `${this.scrollDuration}s`, 'important');
      this._messageText.nativeElement.style.transform = `translate(${-this.scrollAmount}px, 0px)`;
    }
    this.stop();
    this.currentTimeout = window.setTimeout(() => this.triggerQuickHit3(), this.scrollDuration * 1000 + 2500);
  }
}
