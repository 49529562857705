import { Component, OnInit, Input, HostBinding, ElementRef, ViewChild, AfterViewChecked } from '@angular/core';
import { Config } from 'src/app/config';
import { CasinoFilesService } from 'src/app/services/casino-files.service';
import { CurrentGameService } from 'src/app/services/current-game.service';
import runPerFrame, { RunPerFrame } from 'src/app/runPerFrame';
import { AudioService } from 'src/app/services/audio.service';

@Component({
  selector: 'multiplier-wheel',
  templateUrl: './multiplier-wheel.component.html',
  styleUrls: ['./multiplier-wheel.component.css']
})
export class MultiplierWheelComponent implements OnInit, AfterViewChecked {

  @HostBinding('class.zoom-out') zoomOut = false;
  @HostBinding('class.show-number') showNumber = false;
  @HostBinding('class.instant-rotation') instantRotation = false;
  @HostBinding('class.fast-rotation') fastRotation = false;

  @Input() multiplier: string; // needs to be lowercase to work
  @Input() anglePerClick = 15;

  wheelOverlayImage: string;
  wheelAngles: { [key: string]: number; } = {};
  wheelAngle = 0;
  hideIcon = false;

  currentRunners: RunPerFrame[] = [];

  @ViewChild('wheel') _wheel: ElementRef<HTMLImageElement>;
  @ViewChild('icon') _icon: ElementRef<HTMLImageElement>;
  prevIconUrl = '';

  constructor(private el: ElementRef<HTMLElement>, config: Config, public filesService: CasinoFilesService, private audioService: AudioService, public currentGame: CurrentGameService) {
    this.wheelOverlayImage = config.asset_roots.images_root + '/wheel/wheel_overlay.png';
    currentGame.subscribe(() => {
	  filesService.getMultiplierJSON(currentGame.casino).then(multiplierJSON => {
		this.wheelAngles = multiplierJSON.wheelAngles;
	  });
    });
  }

  ngOnInit() {
  }

  ngAfterViewChecked() {
    // hide icon when url changes (with a microtask) and then wait for the new icon to load so onIconLoaded is called and unhides the icon
    if (this._icon.nativeElement.src !== this.prevIconUrl) {
      this.prevIconUrl = this._icon.nativeElement.src;
      Promise.resolve(null).then(() => this.hideIcon = true);
    }
  }

  onIconLoaded() {
    this.hideIcon = false;
  }

  getAnimationRunners() {
    let doClicking = true;
    let prevAngle = 0;
    let angleDiffAccumulator = 0;
    const runners = [
      // zoom out wheel
      runPerFrame((dt, runner) => {
        this.zoomOut = true;
      }, {autorun: false, runDelay: 0, once: true}),
      // spin wheel and stop dramatically on final number
      runPerFrame((dt, runner) => {
        // don't assume wheel is symmetrical
        //this.wheelAngle = 180 * (7 + Math.floor(5 * Math.random())) + this.wheelAngles[this.multiplier];
        this.wheelAngle = 360 * (4 + Math.floor(2 * Math.random())) + this.wheelAngles[this.multiplier];
      }, {autorun: false, runDelay: 900, once: true}),
      // play clicking sound while wheel is spinning, based rotational velocity (difference in rotation per frame)
      runPerFrame((dt, runner) => {
        if (!doClicking) {
          runner.stop();
          return;
        }
        const matrixComponents = window.getComputedStyle(this._wheel.nativeElement).transform.split(/[(, ]+/g);
        const a = parseFloat(matrixComponents[1]);
        const b = parseFloat(matrixComponents[2]);
        const angle = Math.atan2(b, a);
        const diff = Math.abs(angle - prevAngle) * (180 / Math.PI);
        angleDiffAccumulator += diff;
        if (angleDiffAccumulator >= this.anglePerClick) {
          angleDiffAccumulator = 0;
          this.audioService.play('multiplier-click');
        }
        prevAngle = angle;
      }, {autorun: false, runDelay: 900}),
      // stop clicking
      runPerFrame((dt, runner) => {
        doClicking = false;
        this.audioService.play('multiplier-click');
      }, {autorun: false, runDelay: 7100, once: true}),
      // pop out number
      runPerFrame((dt, runner) => {
        this.showNumber = true;
        this.audioService.play('multiplier-number');
      }, {autorun: false, runDelay: 7500, once: true}),
      // zoom back with number showing
      runPerFrame((dt, runner) => {
        this.zoomOut = false;
      }, {autorun: false, runDelay: 8900, once: true}),
    ];
    this.currentRunners = this.currentRunners.concat(runners);
    return runners;
  }

  animate() {
    this.resetWheel();
    const runners = this.getAnimationRunners();
    runners.forEach(runner => runner.run());
    return runners;
  }

  showFinalWheel() {
    this.resetWheel();
    this.showNumber = true;
    this.instantRotation = true;
    this.fastRotation = true;
    setTimeout(() => {
      this.wheelAngle = this.wheelAngles[this.multiplier];
    }, 50);
  }

  resetWheel() {
    // stop and clear all related runners
    this.currentRunners.forEach(runner => runner.stop());
    this.currentRunners = [];
    // reset elements
    const allEls = [this.el.nativeElement].concat(
      Array.from(this.el.nativeElement.querySelectorAll<HTMLElement>('*'))
    );
    allEls.forEach(el => el.style.transition = 'none');
    this.zoomOut = false;
    this.showNumber = false;
    this.instantRotation = false;
    this.fastRotation = false;
    this.wheelAngle = 0;
    setTimeout(() => {
      allEls.forEach(el => el.style.transition = null);
    }, 50);
  }

}
