import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, ViewChildren, QueryList, ChangeDetectionStrategy } from '@angular/core';
import { Jackpot } from 'src/app/models/jackpot';
import Swiper, { SwiperOptions } from 'swiper';
import { Config } from 'src/app/config';

@Component({
  selector: 'jackpot-carousel',
  templateUrl: './jackpot-carousel.component.html',
  styleUrls: ['./jackpot-carousel.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JackpotCarouselComponent implements OnInit, AfterViewInit {

  @Input() jackpots: Jackpot[] = [];
  logoImages: { [key: string]: string } = {};

  @ViewChild('swiper') _swiper: ElementRef<HTMLDivElement>;
  @ViewChild('swiperPagination') _swiperPagination: ElementRef<HTMLDivElement>;
  @ViewChildren('slide') _slides: QueryList<ElementRef<HTMLDivElement>>;

  swiper: Swiper;

  constructor(config: Config) {
    this.logoImages = {
      'QuickHit MEGA': config.asset_roots.images_root + '/icons/quick_hit_3_mega_logo.png',
      'QuickHit MINI': config.asset_roots.images_root + '/icons/quick_hit_3_mini_logo.png',
      'QUICKHIT MEGA': config.asset_roots.images_root + '/icons/quick_hit_3_mega_logo.png',
      'QUICKHIT MINI': config.asset_roots.images_root + '/icons/quick_hit_3_mini_logo.png',
    };
  }

  index(index) { return index; }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this._slides.changes.subscribe(() => this.initSwiper());
    if (this._slides.length) {
      this.initSwiper();
    }
  }

  initSwiper() {
    this.swiper = new Swiper(this._swiper.nativeElement, {
      direction: 'horizontal',
      spaceBetween: 12,
      grabCursor: true,
      speed: 850,
      autoplay: {
        delay: 8500,
        disableOnInteraction: true,
      },
      pagination: {
        el: this._swiperPagination.nativeElement,
        clickable: true,
      },
    });
  }

}
