import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Config } from 'src/app/config';
import { TimezoneService } from 'src/app/services/timezone.service';

@Component({
  selector: 'info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoPanelComponent implements OnInit {

  @Input() name: string;
  @Input() number: number;
  @Input() dateString: string;
  @Input() showMag: boolean;
  @Input() dateFormat = 'M/d/yy - h:mma ZZZZ';

  searchIcon: string;

  constructor(config: Config, public timezoneService: TimezoneService) {
    this.searchIcon = config.asset_roots.images_root + '/icons/icon_search_light.png';
  }

  ngOnInit() {
  }

}
