import { Component, OnInit, Input, ViewChildren, QueryList, ElementRef, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'preload-images',
  templateUrl: './preload-images.component.html',
  styleUrls: ['./preload-images.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreloadImagesComponent implements OnInit {

  @Input() id: string;
  @Input() urls: string[];

  @ViewChildren('img') _imgs: QueryList<ElementRef<HTMLImageElement>>;

  constructor() { }
  index(index) { return index; }

  ngOnInit() {
  }
}
