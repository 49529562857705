import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'grid-selection',
  templateUrl: './grid-selection.component.html',
  styleUrls: ['./grid-selection.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridSelectionComponent implements OnInit {

  @Input() numbers: number[];
  @Input() maxNumbers = Infinity;
  @Output() changed = new EventEmitter();

  constructor() { }

  idx(idx) { return idx; }

  ngOnInit() {
  }

  isNumberSelected(num: number) {
    return this.numbers.indexOf(num) >= 0;
  }

  toggleNumber(num: number) {
    if (this.isNumberSelected(num)) {
      this.numbers = this.numbers.filter(n => n !== num);
    } else if (this.numbers.length < this.maxNumbers) {
      this.numbers = this.numbers.concat(num);
    }
    this.changed.emit({numbers: this.numbers});
  }
}
