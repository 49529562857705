import { Component, OnInit, HostBinding, Input, ElementRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { Config } from 'src/app/config';

declare var $;

@Component({
  selector: 'game-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements OnInit {

  @HostBinding('class') _class = 'modal fade';
  @HostBinding('tabindex') _tabindex = -1;

  @Input() title: string;
  @Input() forceMaxHeight = false; // used to prevent modal from annoyingly jumping in size when content fills in
  @Output() shown = new EventEmitter<HTMLElement>();
  @Output() hidden = new EventEmitter<HTMLElement>();

  closeImage: string;

  constructor(private el: ElementRef<HTMLElement>, private config: Config) {
    this.closeImage = config.asset_roots.images_root + '/icons/icon_close.png';
    $(this.el.nativeElement).on('show.bs.modal', e => {
      this.shown.emit(this.el.nativeElement);
    });
    $(this.el.nativeElement).on('hide.bs.modal', e => {
      this.hidden.emit(this.el.nativeElement);
    });
  }

  ngOnInit() {
  }

  show() {
    $(this.el.nativeElement).modal('show');
  }
  hide() {
    $(this.el.nativeElement).modal('hide');
  }

}
