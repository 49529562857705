import {Component, OnInit, ChangeDetectionStrategy, HostBinding, Input} from '@angular/core';
import { Config } from 'src/app/config';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpComponent implements OnInit {

  @HostBinding('class.embedded') @Input() embedded = false;
  images: { [key: string]: string } = {};

  constructor(config: Config) {
    this.images = {
      animation:             config.asset_roots.help_root + '/animation.png',
      sound:                 config.asset_roots.help_root + '/audio.png',
      gameInfo:              config.asset_roots.help_root + '/game_status.png',
      prevGame:              config.asset_roots.help_root + '/arrow _left.png',
      nextGame:              config.asset_roots.help_root + '/arrow _right.png',
      searchIcon:            config.asset_roots.help_root + '/search.png',
      hotCold:               config.asset_roots.help_root + '/hot_cold.png',
      gridHotCold:           config.asset_roots.help_root + '/game_grid_hot_cold.png',
      ticketSearch:          config.asset_roots.help_root + '/ticket_search_btn.png',
      ticketSearchModal:     config.asset_roots.help_root + '/ticket_search.png',
      ticketSearchResults:   config.asset_roots.help_root + '/ticket_search_results.png',
      luckyNumbers:          config.asset_roots.help_root + '/btn_my_numbers.png',
      luckyNumbersModal:     config.asset_roots.help_root + '/my_lucky_numbers.png',
      luckyNumbersResults:   config.asset_roots.help_root + '/my_lucky_numbers_results.png',
      gameSearch:            config.asset_roots.help_root + '/game_search_btn.png',
      gameSearchModal:       config.asset_roots.help_root + '/game_search.png',
      paytable:              config.asset_roots.help_root + '/paytable_btn.png',
      liveGame:              config.asset_roots.help_root + '/live_btn.png',
    };
  }

  ngOnInit() {
  }

}
