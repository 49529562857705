import {Component, ElementRef, OnInit, ViewChild, OnDestroy, AfterViewInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Config} from '../../config';
import {NgxSpinnerService} from 'ngx-spinner';
import {CasinoService} from '../../services/casino.service';
import {Casino} from '../../models/casino';
import {BallDraw} from '../../models/balldraw';
import {Game} from '../../models/game';
import { GridState } from 'src/app/enums';
import { CanvasAnimationComponent } from '../canvas-animation/canvas-animation.component';
import { Observable, Subscription } from 'rxjs';
import runPerFrame, { RunPerFrame } from 'src/app/runPerFrame';
import { MultiplierWheelComponent } from '../multiplier-wheel/multiplier-wheel.component';
import { GameMenuOption, GameMenuComponent } from '../game-menu/game-menu.component';
import { ModalComponent } from '../modal/modal.component';
import { Jackpot } from 'src/app/models/jackpot';
import { Ticket } from 'src/app/models/ticket';
import { DateTime } from 'luxon';
import { TimezoneService } from 'src/app/services/timezone.service';
import { Settings } from 'src/app/settings';
import { QuickHit3Component } from '../quick-hit-3/quick-hit-3.component';
import { TilesComponent } from '../tiles/tiles.component';
import { CasinoFilesService } from 'src/app/services/casino-files.service';
import { CurrentGameService } from 'src/app/services/current-game.service';
import { BallComponent } from '../ball/ball.component';
import { AudioService } from 'src/app/services/audio.service';

//#endregion
@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css']
})
export class GameComponent implements OnInit, OnDestroy, AfterViewInit {
  static LUCKY_NUMBERS_KEY = 'lucky-numbers';

  intervalsToClear: number[] = [];
  menuOptions: GameMenuOption[];

  casino: Casino;
  game: Game;

  currentBallDraw: BallDraw;
  currentBallDrawIndex = 0;
  currentBalls: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  currentBonusBalls: number[] = [0, 0, 0, 0];

  jackpots: Jackpot[] = [];
  liveBallDrawRaceId = 0;

  gridState = GridState.Game;
  hotNumbers: number[];
  coldNumbers: number[];
  recentBallDraws: BallDraw[];
  recentBallDrawsPagesToShow = 1;

  maxLuckyNumbers = 20;
  luckyNumbers: number[];
  luckyNumberDraw: BallDraw;
  luckyNumberDrawMatchingNumbers = 0;

  ticketSearchId = '';
  ticketSearchPIN = '';
  ticketSearchResult: Ticket;

  gameSearchNumber = '';
  gameSearchDateString = '';
  endOfGames = false;
  allBallDraws: BallDraw[];

  get gameSearchDate() { return this.gameSearchDateString ? DateTime.fromFormat(this.gameSearchDateString, 'yyyy-MM-dd').toJSDate() : null; }
  get gameSearchDateDisplay() { return this.gameSearchDateString ? DateTime.fromFormat(this.gameSearchDateString, 'yyyy-MM-dd').toFormat('M/dd/yyyy (ZZZZ)') : null; }
  gameSearchResult: BallDraw;

  gamePrintStartNumber = 0;
  gamePrintEndNumber = 0;

  loadingSpinnerRequests: string[] = [];
  loadingSpinnerDebounceMillis = 120;
  loadingSpinnerDebounceId: number;
  ballDrawAnimationRunners: RunPerFrame[] = [];

  gamingArtsLogo: string;
  kenoCloudLogo: string;
  menuImage: string;
  arrowLeftImage: string;
  arrowRightImage: string;
  hotColdImage: string;
  myNumbersImage: string;
  ticketSearchImage: string;
  gameSearchImage: string;
  paytablesImage: string;
  disclaimerImage: string;
  skipGameImage: string;
  liveGameImage: string;
  muteOffImage: string;
  muteOnImage: string;
  muteOffDesktopImage: string;
  muteOnDesktopImage: string;
  settingsDesktopImage: string;
  searchImage: string;
  searchLightImage: string;
  quickHit3Logo: string;
  quickHit3MiniLogo: string;
  haveYouPlayedImage: string;

  miscImagesToPreload: string[] = [];
  ernieImages: string[] = [];
  hopperImages: string[] = [];
  ballImages: string[] = [];
  ballCallIcons: string[] = [];

  isLiveGame: boolean = false; 
  prevGameLoaded: boolean = false; 
  prevLiveBallDrawRaceId = 0;

  showHotCold = true;
  showGameHistoryArrows = true;
  showGameSearch = true;
  showTicketSearch = true;
  showMyNumbers = true;
  showMultiplier = true;
  showGoldenKenoBall = true;
  maxGameSearch: number;
  casinoStateLocation: string;
  
  @ViewChild('gameMenu') _gameMenu: GameMenuComponent;
  @ViewChild('ernieAnimation') _ernieAnimation: CanvasAnimationComponent;
  @ViewChild('hopperAnimation') _hopperAnimation: CanvasAnimationComponent;
  @ViewChild('ballAnimation') _ballAnimation: BallComponent;
  @ViewChild('ballAnimation', {read: ElementRef}) _ballAnimationEl: ElementRef<HTMLDivElement>;
  @ViewChild('ballZoomer') _ballZoomer: BallComponent;
  @ViewChild('ballZoomer', {read: ElementRef}) _ballZoomerEl: ElementRef<HTMLDivElement>;
  @ViewChild('multiplierWheel') _multiplierWheel: MultiplierWheelComponent;
  @ViewChild('centerQuickHit3') _centerQuickHit3: QuickHit3Component;
  @ViewChild('tilesTop') _tilesTop: TilesComponent;
  @ViewChild('tilesBottom') _tilesBottom: TilesComponent;
  @ViewChild('footerButtons') _footerButtons: ElementRef<HTMLDivElement>;
  @ViewChild('kenoGrid') _kenoGrid: ElementRef<HTMLDivElement>;

  @ViewChild('modalDetails') public _modalDetails: ModalComponent;
  @ViewChild('modalPaytables') public _modalPaytables: ModalComponent;
  @ViewChild('modalLuckyNumbers') public _modalLuckyNumbers: ModalComponent;
  @ViewChild('modalLuckyNumbersFound') public _modalLuckyNumbersFound: ModalComponent;
  @ViewChild('modalLuckyNumbersNotFound') public _modalLuckyNumbersNotFound: ModalComponent;
  @ViewChild('modalTicketSearch') public _modalTicketSearch: ModalComponent;
  @ViewChild('modalTicketFound') public _modalTicketFound: ModalComponent;
  @ViewChild('modalTicketNotFound') public _modalTicketNotFound: ModalComponent;
  @ViewChild('modalGameSearch') public _modalGameSearch: ModalComponent;
  @ViewChild('modalGameNotFound') public _modalGameNotFound: ModalComponent;
  @ViewChild('modalPrint') public _modalPrint: ModalComponent;
  @ViewChild('modalSettings') public _modalSettings: ModalComponent;
  @ViewChild('modalHelp') public _modalHelp: ModalComponent;
  @ViewChild('modalFAQs') public _modalFAQs: ModalComponent;
  @ViewChild('modalDisclaimer') public _modalDisclaimer: ModalComponent;
  @ViewChild('modalPrivacy') public _modalPrivacy: ModalComponent;

  constructor(
      private config: Config, public settings: Settings, private route: ActivatedRoute, private router: Router,
      private casinoService: CasinoService, public filesService: CasinoFilesService, public audioService: AudioService, public timezoneService: TimezoneService,
      private currentGameService: CurrentGameService,
      private spinner: NgxSpinnerService
  ) {
    (window as any).GAME = this;

    this.gamingArtsLogo = config.asset_roots.images_root + '/gamingArtsLogo.png';
    this.kenoCloudLogo = config.asset_roots.images_root + '/Keno_Cloud_Logo.png';

    currentGameService.subscribe(() => { // subscribing to current game updates is necessary to get the logo for the Casino Info option below
      this.menuOptions = [
        {
          title: 'Home',
          image: config.asset_roots.images_root + '/icons/icon_menu_home.png',
          onClick: () => this.router.navigate(['home']),
        },
        {
          title: 'Casino Info',
          image: filesService.getLogo(this.casino),
          onClick: () => this._modalDetails.show(),
        },
        {
          title: 'Lucky Numbers',
          image: config.asset_roots.images_root + '/icons/icon_menu_luckynumbers.png',
          onClick: () => this._modalLuckyNumbers.show(),
        },
        {
          title: 'Game Search',
          image: config.asset_roots.images_root + '/icons/icon_menu_search_game.png',
          onClick: () => this._modalGameSearch.show(),
        },
        {
          title: 'Ticket Search',
          image: config.asset_roots.images_root + '/icons/icon_menu_search_ticket.png',
          onClick: () => this._modalTicketSearch.show(),
        },
        {
          title: 'Paytables',
          image: config.asset_roots.images_root + '/icons/icon_menu_paytables.png',
          onClick: () => this._modalPaytables.show(),
        },
        {
          title: 'Settings',
          image: config.asset_roots.images_root + '/icons/icon_menu_settings.png',
          onClick: () => this._modalSettings.show(),
        },
        {
          title: 'Help',
          image: config.asset_roots.images_root + '/icons/icon_menu_helpfaq.png',
          onClick: () => this._modalHelp.show(),
        },
        {
          title: 'FAQs',
          image: config.asset_roots.images_root + '/icons/icon_menu_helpfaq.png',
          onClick: () => this._modalFAQs.show(),
        },
        {
          title: 'Disclaimer',
          image: config.asset_roots.images_root + '/icons/icon_menu_disclaimer.png',
          onClick: () => this._modalDisclaimer.show(),
        },
        {
          title: 'Privacy Policy',
          image: config.asset_roots.images_root + '/icons/icon_menu_disclaimer.png',
          onClick: () => this._modalPrivacy.show(),
        },
      ];
    });

    this.menuImage = config.asset_roots.images_root + '/icons/icon_menu.png';
    this.arrowLeftImage = config.asset_roots.images_root + '/icons/icon_arrow_left.png';
    this.arrowRightImage = config.asset_roots.images_root + '/icons/icon_arrow.png';
    this.hotColdImage = config.asset_roots.images_root + '/icons/icon_hotcold.png';
    this.myNumbersImage = config.asset_roots.images_root + '/icons/icon_luckynumbers.png';
    this.ticketSearchImage = config.asset_roots.images_root + '/icons/icon_search_ticket.png';
    this.gameSearchImage = config.asset_roots.images_root + '/icons/icon_menu_search_game.png',
    this.paytablesImage = config.asset_roots.images_root + '/icons/icon_menu_paytables.png',
    this.disclaimerImage = config.asset_roots.images_root + '/icons/icon_menu_disclaimer.png',
    this.skipGameImage = config.asset_roots.images_root + '/icons/icon_skip.png';
    this.liveGameImage = config.asset_roots.images_root + '/icons/icon_live.png';
    this.muteOnImage = config.asset_roots.images_root + '/icons/icon_sound_on.png';
    this.muteOffImage = config.asset_roots.images_root + '/icons/icon_sound_off.png';
    this.muteOnDesktopImage = config.asset_roots.images_root + '/icons/icon_sound_on_desktop.png';
    this.muteOffDesktopImage = config.asset_roots.images_root + '/icons/icon_sound_off_desktop.png';
    this.settingsDesktopImage = config.asset_roots.images_root + '/icons/icon_settings_desktop.png';
    this.searchImage = config.asset_roots.images_root + '/icons/icon_search.png';
    this.searchLightImage = config.asset_roots.images_root + '/icons/icon_search_light.png';
    this.quickHit3Logo = config.asset_roots.images_root + '/icons/quick_hit_3_mega_logo.png';
    this.quickHit3MiniLogo = config.asset_roots.images_root + '/icons/quick_hit_3_mini_logo.png';
    this.haveYouPlayedImage = config.asset_roots.images_root + '/have_you_played_your_lucky_numbers.png';

    this.miscImagesToPreload.push(config.asset_roots.images_root + '/flask.png');
    for (let i = 1; i <= 41; i++) {
      this.ernieImages.push(`${config.asset_roots.images_root}/ernie/Ball_Throw_${i}.png`);
    }
    for (let i = 1; i <= 34; i++) {
      this.hopperImages.push(`${config.asset_roots.images_root}/ballHopper/blower_128x128 (${i}).png`);
    }
    for (const type of ['normal', 'quickHit3', 'golden']) {
      this.ballImages.push(`${config.asset_roots.images_root}/balls/ball_${type}.png`);
    }
    for (let i = 1; i <= 20; i++) {
      this.ballCallIcons.push(`${config.asset_roots.images_root}/icons/icon_ballcall_${(i < 10 ? '0' : '') + i}.png`);
    }

    // init settings
    this.luckyNumbers = JSON.parse(localStorage.getItem(GameComponent.LUCKY_NUMBERS_KEY)) || []; // TODO(mkhan): move lucky numbers to settings file somehow
    this.audioService.mute = !this.settings.enableAudio;
    this.settings.saved.subscribe(s => {
      this.audioService.mute = !s.enableAudio;
    });
  }

  ngOnInit() {
    this._ballAnimationEl.nativeElement.style.opacity = '0'; // Fixes ball appearing during loading

    // measuring certain elements and storing into custom CSS properties for styles to use
    this.intervalsToClear.push(window.setInterval(() => {
      const newFBHeight = `${this._footerButtons.nativeElement.offsetHeight}px`;
      if (document.documentElement.style.getPropertyValue('--footer-buttons-height') !== newFBHeight) {
        document.documentElement.style.setProperty('--footer-buttons-height', newFBHeight);
      }
      const newKGTop = `${window.innerHeight - this._kenoGrid.nativeElement.offsetTop}px`;
      if (document.documentElement.style.getPropertyValue('--keno-grid-top-from-bottom') !== newKGTop) {
        document.documentElement.style.setProperty('--keno-grid-top-from-bottom', newKGTop);
      }
    }, 200));

    // casino loading
    this.addLoadingSpinnerRequest('load-casino');
    const casinoName = this.route.snapshot.paramMap.get('name');
    const gameId = parseInt(this.route.snapshot.paramMap.get('gameid'), 10) || 1;
    const initPromises = [
      // get casino info
      this.casinoService.casino(casinoName).toPromise()
        .then(casino => {
          this.casino = casino;
          this.casinoStateLocation = casino.state;
          this.setFeatureRestrictions();

          // get post-casino info
          return Promise.all([
            this.casinoService.latestBallDrawFor(casino, {gameid: gameId}).toPromise(),
            this.casinoService.casinoGames(casino).toPromise(),
            this.casinoService.jackpots(casino).toPromise(),
          ]);
        })
        .then(([ballDraw, casinoGames, jackpots]) => {
          this.liveBallDrawRaceId = ballDraw.raceid; // keep track of latest live game ID to flag whether the current game is live
          this.game = casinoGames.results.find(g => g.gameid === gameId);
          this.jackpots = jackpots.results;
          this.currentGameService.set(this.casino, this.game);
          // keep track of the casino/game that was previously loaded so we can jump back into it from the home page
          this.settings.previouslyLoadedCasino = this.casino;
          this.settings.previouslyLoadedGame = this.game.gameid;
          this.settings.save();

          // load all games for current casino
          this.casinoService.allBallDrawsFor(this.casino, this.game).subscribe(draws => {
            this.allBallDraws = this.maxGameSearch ? draws.results.splice(0, this.maxGameSearch) : draws.results;
          });
        }),
    ];

    // post-init kick-off
    Promise.all(initPromises).then(() => {
      this.loadLiveGame();
      this.removeLoadingSpinnerRequest('load-casino');

      // poll to update latest live game ID
      const LIVE_GAME_POLL_INTERVAL = 7000;
      this.intervalsToClear.push(window.setInterval(() => {
        this.casinoService.latestBallDrawFor(this.casino, this.game).subscribe(ballDraw => {
          this.liveBallDrawRaceId = ballDraw.raceid;
        });
      }, LIVE_GAME_POLL_INTERVAL));

      // poll to update latest jackpot values
      const JACKPOTS_POLL_INTERVAL = 9000;
      this.intervalsToClear.push(window.setInterval(() => {
        this.casinoService.jackpots(this.casino).subscribe(jackpots => {
          this.jackpots = jackpots.results;
        });
      }, JACKPOTS_POLL_INTERVAL));
    });
  }

  ngOnDestroy() {
    document.documentElement.style.setProperty('--footer-buttons-height', '0px');
    this.ballDrawAnimationRunners.forEach(r => r.stop());
    this.audioService.stopAll();
    this.loadingSpinnerRequests = [];
    this.removeLoadingSpinnerRequest('');
    this.intervalsToClear.forEach(i => clearInterval(i));
    this.intervalsToClear = [];
    this.currentGameService.clear();
  }

  ngAfterViewInit() {
  }

   ngDoCheck() {
/*       if(this.currentBallDrawIndex > 0 && this.currentBalls && !document.getElementById("live-game").hidden){
        document.getElementById("live-game").hidden = true; 
        setTimeout(() => {          
          this.loadLiveGame();
        }, 20000);
      } */

      if( this.prevLiveBallDrawRaceId > 0 && this.prevLiveBallDrawRaceId !== this.liveBallDrawRaceId ){
        this.loadLiveGame();
        this.prevLiveBallDrawRaceId = this.liveBallDrawRaceId;
      }
  } 

  isLiveDraw() {
    if( this.currentBallDraw && this.currentBallDraw.raceid !== this.liveBallDrawRaceId){
      this.prevLiveBallDrawRaceId = this.liveBallDrawRaceId;
    }
    return this.currentBallDraw && this.currentBallDraw.raceid === this.liveBallDrawRaceId;
  }

  isOnLiveGameView() {
    return this.isLiveDraw() && this.gridState === GridState.Game;
  }

  hasQuickHit3() {
    return this.jackpots.find(j => j.name.toLowerCase() === 'Quick Hit 3');
  }

  addLoadingSpinnerRequest(key: string) {
    this.loadingSpinnerRequests.push(key);
    clearTimeout(this.loadingSpinnerDebounceId);
    this.loadingSpinnerDebounceId = window.setTimeout(() => {
      this.spinner.show();
    }, this.loadingSpinnerDebounceMillis);
  }
  removeLoadingSpinnerRequest(key: string) {
    this.loadingSpinnerRequests = this.loadingSpinnerRequests.filter(r => r !== key);
    if (this.loadingSpinnerRequests.length === 0) {
      clearTimeout(this.loadingSpinnerDebounceId);
      this.spinner.hide();
    }
  }

  loadLiveGame() {
    this.showGame(this.casinoService.latestBallDrawFor(this.casino, this.game));
  }
  loadNextGame() {
    this.endOfGames = false;
    this.showGame(this.casinoService.nextBallDraw(this.casino, this.game, this.currentBallDraw.raceid));
  }
  loadPrevGame() {
    this.showGame(this.casinoService.previousBallDraw(this.casino, this.game, this.currentBallDraw.raceid));
  }
  loadGameByRaceId(raceId: number) {
    this.showGame(this.casinoService.getBallDraw(this.casino, this.game, raceId));
  }

  showGame(ballDrawRequest: Observable<BallDraw>) {
    // clear state
    this.currentBonusBalls = [];
    this.currentBalls = [];
    // clear animations
    this._ernieAnimation.drawFirstFrame();
    this._hopperAnimation.drawFirstFrame();
    this._ballAnimationEl.nativeElement.classList.remove('play-animation');
    this._ballZoomerEl.nativeElement.classList.remove('show');
    this.ballDrawAnimationRunners.forEach(r => r.stop());
    this.audioService.stopAll();
    if(this._multiplierWheel) {
      this._multiplierWheel.resetWheel();
    }
    // turn on spinner
    this.addLoadingSpinnerRequest('show-game');
    // resolve request
    ballDrawRequest.subscribe(res => {
      if (res.raceid != null) {
        this.currentBallDraw = res;

        // Check if previous game can be displayed
        const showPrevGame = this.allBallDraws ? this.allBallDraws.findIndex((g) => g.racenumber === Number(this.currentBallDraw.racenumber))+1 < this.allBallDraws.length ? true : false : true;

        if (showPrevGame) {
          const playAnimations = this.isLiveDraw() && this.settings.enableAnimations;
          // handle animations
          if (playAnimations) {
            this.ballDrawAnimationRunners = this.runBallDrawAnimations();
          } else {
            this.skipAnimations();
          }
          // handle center scrolling
          if (playAnimations && this.hasQuickHit3()) {
            this._centerQuickHit3.triggerQuickHit3();
            this._centerQuickHit3.stop();
          } else {
            this._centerQuickHit3.triggerHaveYouPlayed();
          }
        } else {
          this.endOfGames = true;
        }
      } else {
        this.loadLiveGame();
      }
      this.removeLoadingSpinnerRequest('show-game');
    });
  }

  runBallDrawAnimations() {
    let startAnimationT = 0;
    let stopAnimationT = 0;
    let finalBallSoundT = 0;
    let ballFloatAnimationT = 0;
    let ballZoomerT = 0;
    let ballZoomerImgSrcT = 0;
    let updateBallsT = 0;
    let ballIndex = -1; // will be incremented when the first ball animation starts
    const delay = this.settings.enableAudio ? 3200 : 300; // give time for intro audio to play
    const multiplierRunners = this._multiplierWheel ? this._multiplierWheel.getAnimationRunners() : [];
    const centerScrollRunner = runPerFrame(dt => this._centerQuickHit3.triggerHaveYouPlayed(), {autorun: false, runDelay: 9300, once: true});
    const ballDrawRunner = runPerFrame(dt => {
      startAnimationT -= dt;
      // main loop for starting a new ball draw animation (if the timer for stopping the animation hasn't been set)
      if (stopAnimationT > 0) {
        stopAnimationT -= dt;
        if (stopAnimationT <= 0) {
          // stop ball drawing loop and start the multiplier & center scroll animation
          ballDrawRunner.stop();
          multiplierRunners.forEach(runner => runner.run());
          centerScrollRunner.run();
        }
      } else if (startAnimationT <= 0) {
        ballIndex++;
        // second-to-last ball plays a special sound (if sound is enabled) at the end to introduce the final ball
        if (ballIndex === (this.currentBallDraw.balls.length - 2) && this.settings.enableAudio) {
          finalBallSoundT = 2000;
          startAnimationT = 3500;
        } else {
          // other balls just play ball animation regularly
          startAnimationT = 1600;
        }
        // set timer to stop runner when we get to the end of drawing
        if (!this.currentBallDraw.balls[ballIndex]) {
          stopAnimationT = 1800;
          return;
        }
        this.currentBallDrawIndex = ballIndex;
        // start ernie/hopper anims
        this._ernieAnimation.replayAnimation();
        this._hopperAnimation.replayAnimation();
        // then set timer for the ball anim
        ballFloatAnimationT = 400;
      }
      // start ball floating animation slightly after the ball draw animation starts
      if (ballFloatAnimationT > 0) {
        ballFloatAnimationT -= dt;
        if (ballFloatAnimationT <= 0) {
          // set the floating ball graphic on anim
          const ball = this.currentBallDraw.balls[ballIndex];
          this._ballAnimation.number = ball;
          this._ballAnimation.quickHit3 = (this.currentBallDraw.bonus_balls || []).indexOf(ball) >= 0;
          this._ballAnimation.golden = this.currentBallDraw.megaball === ball;
          // start ball animation
          this._ballAnimationEl.nativeElement.classList.remove('play-animation');
          this._ballAnimationEl.nativeElement.style.opacity = '0';
          setTimeout(() => this._ballAnimationEl.nativeElement.classList.add('play-animation'), 30);
          this.audioService.playBallNumber(ball);
          // set timers for final ball zoom to tile
          ballZoomerT = 1550;
          ballZoomerImgSrcT = 1100;
        }
      }
      // set the img on the ball zoomer a bit before the zoomer is actually activated to give it time to load, but also allow the previous zoomer to keep its number until it zooms in completely
      if (ballZoomerImgSrcT > 0) {
        ballZoomerImgSrcT -= dt;
        if (ballZoomerImgSrcT <= 0) {
          this._ballZoomer.number = this._ballAnimation.number;
          this._ballZoomer.quickHit3 = this._ballAnimation.quickHit3;
          this._ballZoomer.golden = this._ballAnimation.golden;
        }
      }
      // after the ball floats up and lingers, show a zoom in towards the destination tile, using a second image to allow the next ball animation to start
      if (ballZoomerT > 0) {
        ballZoomerT -= dt;
        if (ballZoomerT <= 0) {
          this._ballZoomerEl.nativeElement.classList.add('show');
          this._ballZoomerEl.nativeElement.style.transitionDuration = '0s';
          this._ballZoomerEl.nativeElement.style.transform = null;
          // set starting transform to ball location
          const ballBounds = this._ballAnimationEl.nativeElement.getBoundingClientRect();
          this._ballZoomerEl.nativeElement.style.width = `${ballBounds.width}px`;
          this._ballZoomerEl.nativeElement.style.height = `${ballBounds.height}px`;
          this._ballZoomerEl.nativeElement.style.transform = `translate(${ballBounds.left}px, ${ballBounds.top}px) scale(1.0)`;
          // calculate and set destination transform to the target tile
          const destination = this._ballZoomer.number > 40 ? this._tilesBottom.getTileByNumber(this._ballZoomer.number) : this._tilesTop.getTileByNumber(this._ballZoomer.number);
          const destinationBounds = destination.getBoundingClientRect();
          const destinationCenterX = destinationBounds.left + destinationBounds.width / 2;
          const destinationCenterY = destinationBounds.top + destinationBounds.height / 2;

          {
            // This class is required for iPad not to display stretched balls in the flasks, but it causes a graphical pop if allowed on the zoomer.
            var kenoBall = this._ballZoomerEl.nativeElement.getElementsByClassName("keno-flask-ball-image")[0] as HTMLElement;
            if (kenoBall) kenoBall.classList.remove("keno-flask-ball-image");

            // If you don't toggle the width/height styles like this, iPads won't get the memo and the text will be too large for the ball.
            var svgText = this._ballZoomerEl.nativeElement.getElementsByClassName("number")[0] as HTMLElement;
            svgText.style.width = "100%";
            svgText.style.height = "100%";
            setTimeout(() => {
              svgText.style.width = "auto";
              svgText.style.height = "auto";
            }, 1);
          }

          setTimeout(() => {
            this._ballZoomerEl.nativeElement.style.transitionDuration = null;
            this._ballZoomerEl.nativeElement.style.transform = `translate(calc(${destinationCenterX}px - 50%), calc(${destinationCenterY}px - 50%)) scale(0)`;
          }, 30);
          // set timer for ball display to be updated
          updateBallsT = 620;
        }
      }
      // when the floating ball rises up and zooms into tile, update the main grid and ball tubes to cause tile pop-in animation
      if (updateBallsT > 0) {
        updateBallsT -= dt;
        if (updateBallsT <= 0) {
          const index = this.currentBallDraw.balls.indexOf(this._ballZoomer.number) + 1; // use zoomer number since ballIndex will have been incremented already for the next animation
          this.currentBalls = this.currentBallDraw.balls.slice(0, index);
          const newBonusBalls = (this.currentBallDraw.bonus_balls || []).filter(b => this.currentBalls.includes(b));
          if (newBonusBalls.length !== this.currentBonusBalls.length) {
            this.currentBonusBalls = newBonusBalls;
          }
        }
      }
      // at the end of all the animations for the second-to-last ball, play an introductory sound for the final ball
      if (finalBallSoundT > 0) {
        finalBallSoundT -= dt;
        if (finalBallSoundT <= 0) {
          this.audioService.play(this.currentBallDraw.megaball ? 'goldenball' : 'lastball');
        }
      }
    }, { runDelay: delay });

    const introAudioRunners = this.settings.enableAudio ? [
      runPerFrame(dt => this.audioService.play('goodluck'), { runDelay: 200, once: true }),
      runPerFrame(dt => this.audioService.play('luckynumbers'), { runDelay: 1200, once: true }),
    ] : [];

    return [ballDrawRunner, centerScrollRunner].concat(multiplierRunners).concat(introAudioRunners);
  }

  skipAnimations() {
/*     if( !document.getElementById("live-game").hidden){
      this.prevLiveBallDrawRaceId = this.liveBallDrawRaceId;
    } */
    this.isLiveGame = false;
    this._ernieAnimation.drawFirstFrame();
    this._hopperAnimation.drawFirstFrame();
    this._centerQuickHit3.triggerQuickHit3();
    this._ballAnimationEl.nativeElement.classList.remove('play-animation');
    this._ballZoomerEl.nativeElement.classList.remove('show');
    this.ballDrawAnimationRunners.forEach(r => r.stop());
    this.audioService.stopAll();
    this.currentBalls = this.currentBallDraw.balls;
    this.currentBonusBalls = this.currentBallDraw.bonus_balls;
    if(this._multiplierWheel) {
      this._multiplierWheel.showFinalWheel();
    }
    this.currentBallDrawIndex = 19;
  }

  goToLiveGame() {
    this.gridState = GridState.Game;
    if (!this.isLiveDraw()) { // don't restart drawing animations unless it was on a different draw previously
      this.loadLiveGame();
    }
  }

  updateRecentGames() {
    // only fetch ball draws from the server if the current ones are stale or non-existent
    if (!this.recentBallDraws || (this.recentBallDraws.find(b => b.gameid === this.game.gameid) || <BallDraw>{}).raceid !== this.liveBallDrawRaceId) {
      this.recentBallDraws = null;
      setTimeout(() => { // allow modal to finish opening before fetching, which can cause frame hitches
        this.casinoService.allBallDrawsFor(this.casino, this.game).subscribe(draws => {
          this.recentBallDraws = this.maxGameSearch ? draws.results.splice(0, this.maxGameSearch) : draws.results;
        });
      }, 500);
    }
  }

  searchLuckyNumbers() {
    // fetch all recent ball draws for casino
    this.addLoadingSpinnerRequest('search-lucky-numbers');
    this.casinoService.allBallDrawsFor(this.casino, this.game).subscribe(draws => {
      let bestMatchingNumbers = 0;
      let bestMatchingDraw: BallDraw = null;
      // start at beginning (list is sorted by most recent draw) and look for any draw that matches all numbers, keeping track of the best one
      draws.results = this.maxGameSearch ? draws.results.splice(0, this.maxGameSearch) : draws.results;
      for (let i = 0; i < draws.results.length; i++) {
        const draw = draws.results[i];
        const matches = draw.balls.reduce((acc, ball) => acc + (this.luckyNumbers.includes(ball) ? 1 : 0), 0);
        if (matches > bestMatchingNumbers) {
          bestMatchingNumbers = matches;
          bestMatchingDraw = draw;
        }
        // stop searching if we found a full match
        if (bestMatchingNumbers === this.luckyNumbers.length) {
          break;
        }
      }
      // store best match
      this.luckyNumberDrawMatchingNumbers = bestMatchingNumbers;
      this.luckyNumberDraw = bestMatchingDraw;
      // open the resulting modal based on success or failure
      if (this.luckyNumberDraw) {
        this._modalLuckyNumbersFound.show();
      } else {
        this._modalLuckyNumbersNotFound.show();
      }
      // close the old modal
      this._modalLuckyNumbers.hide();
      this.removeLoadingSpinnerRequest('search-lucky-numbers');
    });
  }

  clearLuckyNumbers() {
    this.saveLuckyNumbers([]);
  }

  saveLuckyNumbers(newLuckyNumbers: number[]) {
    this.luckyNumbers = newLuckyNumbers;
    localStorage.setItem(GameComponent.LUCKY_NUMBERS_KEY, JSON.stringify(this.luckyNumbers));
  }

  searchTicket(ticketId: string, ticketPIN: string) {
    this.addLoadingSpinnerRequest('search-ticket');
    this.casinoService.getTicketWithIdAndPIN(
		this.casino,
		this.game,
		ticketId,
		ticketPIN
	).subscribe(ticket => {
      this.ticketSearchResult = ticket;
      this._modalTicketFound.show();
      this._modalTicketSearch.hide();
      this.removeLoadingSpinnerRequest('search-ticket');
    }, () => {
      this._modalTicketNotFound.show();
      this._modalTicketSearch.hide();
      this.removeLoadingSpinnerRequest('search-ticket');
    });
  }

  resetTicketSearchInputs() {
    this.ticketSearchId = '';
    this.ticketSearchPIN = '';
  }

  searchGame(gameNumber: string, gameDate: Date) {
    this.addLoadingSpinnerRequest('search-game');
    const searchedGame =  this.recentBallDraws.find((g) => g.racenumber === Number(gameNumber));
    if (searchedGame){
      this.casinoService.getBallDrawWithNumberAndDate(this.casino, this.game, gameNumber, gameDate).subscribe(ballDraw => {
        this.loadGameByRaceId(ballDraw.raceid);
        this._modalGameSearch.hide();
        this.removeLoadingSpinnerRequest('search-game');
      }, () => {
        this._modalGameNotFound.show();
        this._modalGameSearch.hide();
        this.removeLoadingSpinnerRequest('search-game');
      });
    } else {
      this._modalGameNotFound.show();
      this._modalGameSearch.hide();
      this.removeLoadingSpinnerRequest('search-game');
    }
  }

  resetGameSearchValues() {
    this.gameSearchNumber = '';
    this.gameSearchDateString = '';
    this.recentBallDrawsPagesToShow = 1;
  }

  toggleMute() {
    this.settings.enableAudio = !this.settings.enableAudio;
    this.settings.save();
  }

  toggleHotColdGameState() {
    switch (this.gridState) {
      case GridState.Game:
        this.gridState = GridState.Hot;
        this.hotNumbers = [];
        this.addLoadingSpinnerRequest('hot-numbers');
        this.casinoService.hotNumbers(this.casino, this.game, this.settings.hotColdHistoryCount).subscribe(res => {
          this.hotNumbers = res.numbers;
          this.removeLoadingSpinnerRequest('hot-numbers');
        });
        break;
      case GridState.Hot:
        this.gridState = GridState.Cold;
        this.coldNumbers = [];
        this.addLoadingSpinnerRequest('cold-numbers');
        this.casinoService.coldNumbers(this.casino, this.game, this.settings.hotColdHistoryCount).subscribe(res => {
          this.coldNumbers = res.numbers;
          this.removeLoadingSpinnerRequest('cold-numbers');
        });
        break;
      case GridState.Cold:
        this.gridState = GridState.Game;
        break;
    }
  }

  getLeftBalls() {
    return this.currentBalls.slice(0, 10);
  }

  getRightBalls() {
    return this.currentBalls.slice(10, 20);
  }

  validateTicketId(e: Event) {
    const num = parseInt((e.target as HTMLInputElement).value.replace(/-/g, ''), 15);
    //const num = parseInt((e.target as HTMLInputElement).value.replace(/-/g, ''), 10);
    if (isNaN(num)) {
      (e.target as HTMLInputElement).value = '';
    } else {
      // (e.target as HTMLInputElement).value = /(\d{1,6})(\d{0,1})(\d{0,1})/g.exec(num.toString())
      //   .slice(1)
      //   .filter(s => s)
      //   .join('-')
      //   ;
    }
    this.ticketSearchId = (e.target as HTMLInputElement).value;
  }

  validateTicketPIN(e: Event) {
  }

  validateMinMax(e: Event, min: number, max: number) {
    const val = Math.max(min, Math.min(max, parseInt((e.target as HTMLInputElement).value.toString(), 10) || 0));
    (e.target as HTMLInputElement).value = val.toString();
    return val;
  }

  validateGameNumber(e: Event, min: number, max: number) {
    const val = this.validateMinMax(e, min, max);
    this.gameSearchNumber = val.toString();
  }
  validateGamePrintStartNumber(e: Event, min: number, max: number) {
    const val = this.validateMinMax(e, min, max);
    this.gamePrintStartNumber = val;
  }
  validateGamePrintEndNumber(e: Event, min: number, max: number) {
    const val = this.validateMinMax(e, min, max);
    this.gamePrintEndNumber = val;
  }
  validateHotCold(e: Event, min: number, max: number) {
    const val = this.validateMinMax(e, min, max);
    this.settings.hotColdHistoryCount = val;
  }
  setFeatureRestrictions() {
    this.config.restrictedFeatures.map(g => {
      if (g.casinoid === this.casino.casinoid) {
        this.showMultiplier = g.feature.multiplier;
        this.showHotCold = g.feature.hotColdNumbers;
        this.showGameHistoryArrows = g.feature.gameHistoryArrows;
        this.showGameSearch = g.feature.gameSearch;
        this.showTicketSearch = g.feature.ticketSearch;
        this.showMyNumbers = g.feature.myNumbers;
        this.showGoldenKenoBall = g.feature.goldenKenoBall;
        this.maxGameSearch = g.feature.maxGameSearch;
        this.maxLuckyNumbers = g.feature.maxLuckyNumbers ?  g.feature.maxLuckyNumbers : this.maxLuckyNumbers;
        this.luckyNumbers = g.feature.maxLuckyNumbers ? this.luckyNumbers.splice(0, g.feature.maxLuckyNumbers) : this.luckyNumbers;
      }
    });
  }

}
