import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'inner-v-css',
  templateUrl: './inner-v-css.component.html',
  styleUrls: ['./inner-v-css.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InnerVCSSComponent implements OnInit, OnDestroy {

  debouncedResize: Observable<Event>;
  subscription: Subscription;

  constructor() {
    this.debouncedResize = fromEvent(window, 'resize').pipe(debounceTime(90));
  }

  onResize() {
    document.documentElement.style.setProperty('--inner-vh', `${window.innerHeight * 0.01}px`);
    document.documentElement.style.setProperty('--inner-vw', `${window.innerWidth * 0.01}px`);
  }

  ngOnInit() {
    this.subscription = this.debouncedResize.subscribe(this.onResize);
    this.onResize();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
