import { Component, OnInit, ChangeDetectionStrategy, SimpleChange, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Settings } from 'src/app/settings';
import { CasinoFilesService } from 'src/app/services/casino-files.service';

@Component({
  selector: 'previous-game-panel',
  templateUrl: './previous-game-panel.component.html',
  styleUrls: ['./previous-game-panel.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviousGamePanelComponent implements OnInit {

  @Input() deactivatedCasinoId: number;
  
  constructor(private router: Router, public settings: Settings, public filesService: CasinoFilesService) { }

  ngOnInit() {}

  ngOnChanges(changes: { [property: string]: SimpleChange }){
    if (changes['deactivatedCasinoId'].currentValue) {
      this.clearPreviousGame();
      localStorage.removeItem('settings-previouslyLoadedCasino');
    }
 }
 
  clearPreviousGame() {
    this.settings.previouslyLoadedCasino = null;
  }

  goToPreviousGame() {
    if (this.settings.previouslyLoadedCasino) {
      this.router.navigate(['game', this.settings.previouslyLoadedCasino.casinoname, this.settings.previouslyLoadedGame]);
    }
  }

}
