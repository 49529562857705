import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AssetRootConfig {
  public asset_root = './assets';
  public images_root = `${this.asset_root}/imgs`;
  public audio_root = `${this.asset_root}/audio`;

  public help_root = `${this.images_root}/help`;
  public ball_root = `${this.images_root}/balls`;

  constructor() {
  }
}
