import {Component, Input, OnChanges, SimpleChange, ChangeDetectionStrategy} from '@angular/core';
import {Config} from '../../config';

@Component({
  selector: 'ball-column',
  templateUrl: './ball-column.component.html',
  styleUrls: ['./ball-column.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BallColumnComponent {

  @Input() balls: number[];
  @Input() bonusBalls: number[];
  @Input() megaBall: number;
  @Input() side: string;
  @Input() numBalls = 10;

  flaskImage: string;

  constructor(private config: Config) {
    this.flaskImage = `url('${config.asset_roots.images_root + '/flask.png'}')`;
  }

  index(index) { return index; }

  getFlaskClass() {
    if (this.side === 'left') {
      return 'flask-left';
    } else {
      return 'flask-right';
    }
  }

  isMillions(ball: number): boolean {
    return (this.bonusBalls || []).indexOf(ball) >= 0;
  }

  isGolden(ball: number) {
    return this.megaBall === ball;
  }
}
