import { Component, OnInit, HostBinding, AfterViewInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TempDataService } from 'src/app/services/temp-data.service';
import { BallDraw } from 'src/app/models/balldraw';
import { TimezoneService } from 'src/app/services/timezone.service';
import { Casino } from 'src/app/models/casino';
import { Game } from 'src/app/models/game';

export class PrintBallDrawsData {
  casino: Casino;
  game: Game;
  ballDraws: BallDraw[];
  luckyNumbers: number[];
  ballsSorted = false;
}

@Component({
  selector: 'print-ball-draws',
  templateUrl: './print-ball-draws.component.html',
  styleUrls: ['./print-ball-draws.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintBallDrawsComponent implements OnInit, AfterViewInit {

  @HostBinding('class.print-page') _printPage = true;

  data: PrintBallDrawsData;

  constructor(private router: Router, private route: ActivatedRoute, private tempData: TempDataService, private timezoneService: TimezoneService) {
    this.data = this.tempData.get<PrintBallDrawsData>('printDrawsData');
  }

  item(index, item: any) { return item; }
  raceid(index, item: BallDraw) { return item.raceid; }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      window.print();
      this.router.navigate([{ outlets: { print: null }}]);
    }, 50);
  }

  getSorted(numbers: number[]) {
    const sorted = [].concat(numbers);
    sorted.sort((a, b) => a - b);
    return sorted;
  }

  isLucky(number: number) {
    return (this.data.luckyNumbers || []).indexOf(number) >= 0;
  }
  isMillions(ballDraw: BallDraw, number: number) {
    return (ballDraw.bonus_balls || []).indexOf(number) >= 0;
  }
  isGolden(ballDraw: BallDraw, number: number) {
    return ballDraw.megaball === number;
  }

}
